import React, { useRef, useState, useEffect } from "react";

import { BiSolidCloudUpload } from "react-icons/bi";

import ImageModal from "./ImageModal";

function ImageInput(props) {
  const {
    field,
    input,
    setInput = () => {},
    width,
    height,
    cropWidth = null,
    cropHeight = null,
    rounded,
    aspect,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [showImage, setShowImage] = useState(false);

  let w =
    !width || !height
      ? { minHeight: "150px", minWidth: "150px" }
      : { width: width, height: height };

  const ref_input = useRef(null);

  const handleSelectFile = (e) => {
    if (typeof field !== "string") {
      console.error("field must be a string");
      return;
    }

    if (typeof input !== "object" || Array.isArray(input)) {
      console.error("input must be an object");
      return;
    }

    let file = e?.target?.files[0] || null;

    if (file) {
      const MAX_FILE_SIZE_MB = 50;
      const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

      if (file.size > MAX_FILE_SIZE_BYTES) {
        console.error("File size exceeds the limit of 50 MB");
        alert("File size exceeds the limit of 50 MB");
        return;
      }

      const inputCpy = { ...input };
      const currentInput = inputCpy[field];
      currentInput.file = file;
      currentInput.src = URL.createObjectURL(file);
      setInput(inputCpy);
      setShowModal(true);
    }
  };

  const openSelector = () => {
    if (typeof field !== "string") {
      console.error("field must be a string");
      return;
    }

    if (typeof input !== "object" || Array.isArray(input)) {
      console.error("input must be an object");
      return;
    }
    if (showModal) {
      return;
    }
    ref_input.current.click();
  };

  return (
    <div
      className=" cursor-pointer relative w-fit border-2 border-dashed bg-azureish-white border-primary"
      style={{
        borderRadius: rounded ? "1000px" : "2px",
        ...w,
      }}
      onClick={openSelector}
    >
      <input
        className="hidden"
        ref={ref_input}
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          //(e);
          handleSelectFile(e);
        }}
      />

      {input && input[field] && typeof input[field]?.src === "string" ? (
        <img
          src={input[field]?.cropSrc || input[field]?.src}
          className="absolute  w-full h-full"
          style={{
            borderRadius: rounded ? "1000px" : "8px",
            objectFit: "contain",
          }}
        />
      ) : null}

      <div
        className=" absolute  px-3  w-full h-full flex justify-center items-center z-50"
        style={{
          borderRadius: rounded ? "1000px" : "8px",
          opacity: showImage ? 0.4 : 1,
          backgroundColor: showImage ? "" : "rgba(255,255,255,0.8)",
        }}
        onMouseOver={() => setShowImage(false)}
        onMouseLeave={() => setShowImage(true)}
      >
        <div className=" w-full h-full  flex flex-col justify-center items-center  ">
          <span className=" text-2xl">
            <BiSolidCloudUpload />
          </span>
          <span className=" text-gray1 px-2 text-center">
            {input[field]?.label || "Upload File"}
          </span>
        </div>
      </div>

      <ImageModal
        cropWidth={cropWidth}
        cropHeight={cropHeight}
        input={input}
        field={field}
        setInput={setInput}
        rounded={rounded}
        aspect={aspect}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default ImageInput;
