import React from "react";
import OtherExpenses from "../../../Features/Tables/OtherExpenses";

const All = () => {
  return (
    <div>
      <OtherExpenses />
    </div>
  );
};

export default All;
