import React from "react";
import UserLogs from '../../../Features/Tables/UserLogs'

const All = () => {
  return <div>
    <UserLogs />
    
  </div>;
};

export default All;
