import React, { useState } from "react";
import TextInput from "../../../Features/Forms/Components/Inputs/TextInput";
import SelectInput from "../../../Features/Forms/Components/Inputs/SelectInput";
import TextAreaInput from "../../../Features/Forms/Components/Inputs/TextArea";
import ImageInput from "../../../Features/Forms/Components/Inputs/ImageInput";
import Btn from "../../../Components/Buttons/Btn";
import topImg from "../../../Assets/images/cobg1.jpg";
import { useNavigate } from "react-router-dom";
import init_company from "./init_company";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState(init_company);
  const [ceo, setCeo] = useState({
    name: { value: "", label: "Name", placeholder: "Enter CEO Name" },
    title: { value: "", label: "Title", placeholder: "Enter Title" },
    gender: { value: "", label: "Gender", placeholder: "Select Gender" },
    mobileNumber: { value: "", label: "Mobile Number", placeholder: "Enter Mobile Number" },
    email: { value: "", label: "Email", placeholder: "Enter Email" },
  });

  return (
    <div className="min-h-screen flex flex-col bg-light_grey">
      {/* Company Details Section */}
      <div className="flex flex-col gap-5 w-full">
        <div
          className="w-full h-72 bg-cover bg-center rounded-lg"
          style={{ backgroundImage: `url(${topImg})` }}
        >
          <h2 className="font-bold text-white text-center w-full h-full text-5xl flex justify-center items-center bg-primary bg-opacity-60 p-4">
            Company Details
          </h2>
        </div>

        <div className="flex ml-6 -mt-28">
          <ImageInput
            input={input}
            setInput={setInput}
            field="logo"
            rounded="full"
            className="border-4 border-secondary shadow-md w-32 h-32"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 max-md:flex max-md:flex-col max-md:gap-4 mt-4 mx-6">
          <TextInput input={input} setInput={setInput} field="companyName" />
          <TextInput input={input} setInput={setInput} field="companyLocation" />
          <TextInput input={input} setInput={setInput} field="mobileNumber" />
          <TextInput input={input} setInput={setInput} field="email" className="col-span-2" />
        </div>

        <div className="grid grid-cols-2 gap-6 max-md:flex max-md:flex-col max-md:gap-4 mt-4 mx-6">
          <TextAreaInput input={input} setInput={setInput} field="companyMission" rows={4} />
          <TextAreaInput input={input} setInput={setInput} field="companyVision" rows={4} />
        </div>
      </div>

      {/* CEO Details */}
      <div className="mt-8 mx-6">
        <h3 className="font-semibold text-center text-2xl text-primary">CEO Details</h3>
        <hr className="mt-2 w-full border-secondary" />
        <div className="grid grid-cols-2 gap-6 max-md:flex max-md:flex-col max-md:gap-4 mt-6">
          <TextInput input={ceo} setInput={setCeo} field="name" />
          <SelectInput input={ceo} setInput={setCeo} field="gender">
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </SelectInput>
          <TextInput input={ceo} setInput={setCeo} field="mobileNumber" />
          <TextInput input={ceo} setInput={setCeo} field="email" />
        </div>
      </div>

      <div className="mt-8 mx-6">
        <Btn btn="primary" size="block"  onClick={() => navigate("/dashboard")}>
          Update
        </Btn>
      </div>
    </div>
  );
};

export default CompanyDetails;



