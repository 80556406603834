import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const fuelData = [
  {
    FVehicleName: "14161647151",
    FStartTime: "2022-04-27T09:01:03",
    FEndTime: "2022-04-27T09:06:05",
    FStartLiter: 70,
    FEndLiter: 448,
    RowNo: 1,
  },
  {
    FVehicleName: "16273849123",
    FStartTime: "2022-04-27T10:01:03",
    FEndTime: "2022-04-27T10:15:05",
    FStartLiter: 150,
    FEndLiter: 350,
    RowNo: 2,
  },
  // Add more data as needed
];

// Helper to extract unique vehicles for the dropdown
const getVehicleOptions = (data) =>
  [...new Set(data.map((entry) => entry.FVehicleName))].map((name) => ({
    value: name,
    label: name,
  }));

const FuelTrajectoryGraph = () => {
  const [filteredData, setFilteredData] = useState(fuelData);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleFilter = () => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = fuelData.filter((entry) => {
      const entryTime = new Date(entry.FStartTime);
      return (
        (!start || entryTime >= start) &&
        (!end || entryTime <= end) &&
        (!selectedVehicle || entry.FVehicleName === selectedVehicle.value)
      );
    });

    setFilteredData(filtered);
  };

  return (
    <div className="flex flex-col items-center w-full h-screen px-4">
      <div className="mb-4 flex items-center gap-4 mt-8 max-md:flex-wrap">
        <div className="flex items-center gap-2">
          <label>Vehicle: </label>
          <Select
            options={getVehicleOptions(fuelData)}
            value={selectedVehicle}
            onChange={setSelectedVehicle}
            isClearable
            placeholder="Select a vehicle"
            className="w-60"
          />
        </div>
        <div>
          <label>Start Date: </label>
          <DatePicker
            selected={startDate}
            className="border rounded-lg p-1"
            showTimeSelect
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div>
          <label>End Date: </label>
          <DatePicker
            selected={endDate}
            className="border rounded-lg p-1"
            showTimeSelect
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          onClick={handleFilter}
        >
          Query
        </button>
      </div>
      <div className="flex-1 w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="FStartTime" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="FStartLiter"
              stroke="#8884d8"
              name="Start Liters"
            />
            <Line
              type="monotone"
              dataKey="FEndLiter"
              stroke="#82ca9d"
              name="End Liters"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FuelTrajectoryGraph;
