import {
  Dashboard,
  Roles,
  Members,
  LogOut,
  Devices,
  FleetRoutes,
  Orders,
  Vehicles,
  Requests,
  FuelMgt,
  Inventory,
  Finance,
  Reports,
  Monitoring,
  CompanySettings,
  Warehouses,
  UserLogs,
  OtherExpenses,
} from "../../Components/Sidebar/sections";

function Sidebar() {
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col overflow-y-auto space-y-2 flex-grow">
        <Dashboard />
        <Vehicles />
        <Devices />
        <FleetRoutes />
        <FuelMgt />
        <Monitoring />
        <Roles />
        <Members />
        <Orders />
        <Warehouses />
        <Requests />
        <Inventory />
        <OtherExpenses />
        <Finance />
        <Reports />
        <UserLogs />
        <CompanySettings />
      </div>
      <div className="mt-auto">
        <LogOut />
      </div>
    </div>
  );
}

export default Sidebar;
