const hasPermission = (user, category, permissionKey) => {
  if (!user || !user?.roles || !user.roles?.[category]) {
    return false;
  }

  const permissions = user?.roles[category];

  return permissions?.[permissionKey]?.is_allowed;
};

export const hasScreenPermission = (user, permissionKey) =>
  hasPermission(user, "screen", permissionKey);

export const hasTablePermission = (user, permissionKey) =>
  hasPermission(user, "table", permissionKey);

export const hasCardPermission = (user, permissionKey) =>
  hasPermission(user, "card", permissionKey);

export const hasGraphPermission = (user, permissionKey) =>
  hasPermission(user, "graph", permissionKey);

export const hasFormPermission = (user, permissionKey) =>
  hasPermission(user, "form", permissionKey);

export const hasOtherPermission = (user, permissionKey) =>
  hasPermission(user, "others", permissionKey);
