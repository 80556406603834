import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Vehicle Reg" field="vehicle_id" setOrder={setOrder} />
      <THTS txt="Name" field="name" setOrder={setOrder} />
      <THTS txt="Last Maintenance Date" field="last_maintenance_date" setOrder={setOrder} />
      {/* <THTS txt="Requested By" field="requested_by" setOrder={setOrder} /> */}
      <THTS txt="Maintenance Cost" field="maintenance_cost" setOrder={setOrder} />
      <THTS txt="Performed By" field="performed_by" setOrder={setOrder} />
      <THTS txt="Actual Maintenance Date" field="actual_maintenance_date" setOrder={setOrder} />
      <THTS txt="Next Maintenance Date" field="next_maintenance_date" setOrder={setOrder} />
      <THTS txt="Description" field="description" setOrder={setOrder} />
      {/* <THT txt="Actions" /> */}
    </Thead>
  );
}

export default TableHead;

