import React, { useState } from "react";
import { TextInput, TextArea, useForm } from "../Components/Inputs";
import init_expense from "./init_expense";
import Btn from "../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useApp from "../../../Hooks/useApp";
import useAxios from "../../../Hooks/useAxios";

const Add = () => {
  const [input, setInput] = useState(init_expense);
  const navigate = useNavigate();
  const { user } = useApp();
  const request = useAxios();

  const handleSubmit = async (data) => {
    const res = await request({
      method: "POST",
      url: "other-expenses/add",
      body: {
        ...data,
        created_by: parseInt(user?.id),
      },
    });

    if (res !== "error") {
      navigate("/dashboard/expenses");
    }
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="w-4/5 max-md:w-full mx-auto bg-white shadow-md rounded-lg mt-8 p-5 border-t">
      <h3 className="text-primary text-center mt-5 font-semibold text-xl mb-4">
        Add Expense
      </h3>
      <div className="flex gap-6 max-md:flex-col max-md:w-full">
        <TextInput input={input} setInput={setInput} field="name" />
        <TextInput input={input} setInput={setInput} field="amount" />
      </div>
      <div className="flex w-full">
        <TextArea input={input} setInput={setInput} field="description" />
      </div>
      <div className="flex justify-between items-center">
        <Btn onClick={() => navigate("/dashboard/expenses")}>Cancel</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
