import Input from "./Utils/Input";

import useInput from "../hooks/useInput";
import inputClass from "../../utils/input.style";

import { MdOutlineAlternateEmail } from "react-icons/md";

function EmailInput(props) {
  const exValidate = {
    pattern: {
      message: "Invalid email",
      value: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
    },
  };

  const { field = "", input, setInput, mt, mb, validate } = props;

  const { handleChange } = useInput({
    input,
    setInput,
    field,
    validate,
    exValidate,
  });

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  return (
    <Input mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <div className=" flex items-center w-full h-full">
        <input
          className={`${inputClass.base} flex-1`}
          value={input[field]?.value || ""}
          placeholder={input[field]?.placeholder || ""}
          onChange={handleChange}
          disabled={input[field]?.disabled || false}
          type="text"
        />
        <span className="mr-4 text-lg">
          <MdOutlineAlternateEmail />
        </span>
      </div>
    </Input>
  );
}

export default EmailInput;
