import React from "react";
// import Devices from '../../../Features/Tables/Devices'
import { lazy, Suspense } from "react";
import useLoadingModal from "../../../Hooks/useLoadingModal";
const Devices = lazy(()=>import('../../../Features/Tables/Devices'))

const All = () => {
  return <div>
    <Suspense fallback={useLoadingModal}>
    <Devices />

    </Suspense>
  </div>;
};

export default All;
