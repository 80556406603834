import { useEffect, useState } from "react";
import useApp from "../../Hooks/useApp";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFonticons } from "react-icons/fa";

function BtnTemplate(props) {
  const [active, setActive] = useState(false);
  const { sideBarOpen, setSideBarOpen, device } = useApp();

  const {
    exactPath = null,
    partialPath = null,
    to = "",
    Icon = <FaFonticons className="text-xl" />,
    label = "Label",
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (to) {
      if (location.pathname === to) {
        setActive(true);
        return;
      }
    }

    if (partialPath) {
      if (location.pathname.includes(exactPath)) {
        setActive(true);
        return;
      }
    }

    setActive(false);
  }, [location, to, partialPath, exactPath]);

  const handleNavigate = () => {
    if (to) {
      navigate(to);
      if (device === "sm") {
        setSideBarOpen(false);
      }
    }
  };

  return (
    <>
      <OpenBtn
        label={label}
        active={active}
        Icon={Icon}
        click={handleNavigate}
        sideBarOpen={sideBarOpen}
      />
      <ClosedBtn
        active={active}
        Icon={Icon}
        click={handleNavigate}
        sideBarOpen={sideBarOpen}
        label={label}
      />
    </>
  );
}

function OpenBtn(props) {
  const { label, active, Icon, click, sideBarOpen } = props;

  if (!sideBarOpen) {
    return null;
  }

  return (
    <div
      className={`relative w-full rounded-md py-2 px-4 text-white flex items-center gap-x-2 cursor-pointer 
      ${active ? "bg-secondary" : ""}
      `}
      onClick={click}
    >
      <span
        className={
          active
            ? "absolute left-0 h-[80%] rounded-xl my-auto p-[3px] bg-white"
            : ""
        }
      ></span>
      <span className="text-2xl cursor-pointer">
        <Icon />
      </span>
      <span className="text-lg font-normal cursor-pointer">{label}</span>
    </div>
  );
}

function ClosedBtn(props) {
  const { active, Icon, click, sideBarOpen, label } = props;

  if (sideBarOpen) {
    return null;
  }

  return (
    <div
      className="flex p-2 justify-center w-full cursor-pointer active:opacity-50"
      onClick={click}
    >
      <span
        className={`text-xl ${
          active
            ? "text-white p-2 rounded-lg bg-primary"
            : "text-auro-metal-saurus hover:text-primary"
        } `}
      >
        <span className="text-2xl">
          <Icon />
        </span>
      </span>
    </div>
  );
}

export default BtnTemplate;
