import { useState, useEffect } from "react";

function useRequestErrorModal() {
  const [showRequestErrorModal, setShowRequestErrorModal] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] =
    useState("Error Message");
  const [requestErrorMessageTimeout, setRequestErrorMessageTimeout] =
    useState(20000);
  const [requestErrorHeader, setRequestErrorHeader] = useState("Oops !");

  useEffect(() => {
    if (showRequestErrorModal) {
      return;
    }
    setRequestErrorMessage("Error Message");
    setRequestErrorMessageTimeout(20000);
    setRequestErrorHeader("Oops !");
  }, [showRequestErrorModal]);

  const handleShow = (obj = {}) => {
    const { header = "Oops !", message = "Error" } = obj;
    setRequestErrorHeader(header);
    setRequestErrorMessage(message);
    setShowRequestErrorModal(true);
  };

  return {
    showRequestErrorModal,
    setShowRequestErrorModal,
    requestErrorMessage,
    setRequestErrorMessage,
    requestErrorMessageTimeout,
    setRequestErrorMessageTimeout,
    requestErrorHeader,
    setRequestErrorHeader,
    handleShow,
  };
}

export default useRequestErrorModal;
