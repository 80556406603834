import React, { useState } from "react";
import Devices from './components/Monitoring'
import MonitoringContext from "../context/MonitoringContext";

const Index = () => {
  const [active, setActive] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  return (
    <MonitoringContext.Provider
      value={{
        search,
        setSearch,
        limit,
        setLimit,
        active,
        setActive,
      
      }}
    >
  
      <div className="mt-4">
        <Devices />
      </div>
    </MonitoringContext.Provider>
  );
};

export default Index;