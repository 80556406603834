function Label(props) {
  const { input } = props;

  if (typeof input?.label !== "string") {
    return (
      <div className="invisible text-base font-medium text-primary">Label</div>
    );
  }

  return (
    <div
      className={`text-xl font-semibold ${
        input?.error ? " text-red-500" : "text-primary"
      }`}
    >
      {input?.label || ""}
    </div>
  );
}

export default Label;
