import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="User ID" field="userId" setOrder={setOrder} />
      <THTS txt="Timestamp" field="timestamp" setOrder={setOrder} />
      <THTS txt="Action" field="action" setOrder={setOrder} />
      <THTS txt="Location" field="location" setOrder={setOrder} />
      <THTS txt="Status" field="status" setOrder={setOrder} />
      
  
    </Thead>
  );
}

export default TableHead;
