import React, { useContext } from "react";
import BasicModal from "./BasicModal";
import Btn from "../Buttons/Btn";

const NotAuthorized = ({ showNotAuthorized, setShowNotAuthorized }) => {
  if (showNotAuthorized) {
    return (
      <BasicModal
        showModal={showNotAuthorized}
        setShowModal={setShowNotAuthorized}
        header=""
        showHeader={false}
      >
        <div className="m-auto p-5">
          <h2 className="text-center text-red text-xl font-bold  mb-6">
            You are not Authorized to perform this operation!!
          </h2>
          <div className="flex justify-between items-center">
            <Btn
              outline={true}
              btn="border"
              size="block"
              onClick={() => setShowNotAuthorized(false)}
            >
              <p className="">Cancel</p>
            </Btn>
          </div>
        </div>
      </BasicModal>
    );
  }
};

export default NotAuthorized;
