import React from "react";
import TripReports from '../../../../Features/Tables/Reports/TripReport'

const All = () => {
  return <div>
    <TripReports />
  </div>;
};

export default All;
