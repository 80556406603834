import React from "react";
import AddVehicles from '../../../Features/Forms/Vehicles/Add'

const Add = () => {
  return <div>
<AddVehicles />
  </div>;
};

export default Add;
