import React from "react";
import NavBtn from "../NavBtn";
import { LuSchool } from "react-icons/lu";

const Dashboard = (props) => {
  const {
    exactPath = "/dashboard",
    to = "/dashboard",
    partialPath = null,
    label = "Dashboard",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={LuSchool}
    />
  );
};

export default Dashboard;
