import { TR, TDT, TA, TD } from "../../../components";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";

function TableRow(props) {
  const { doc } = props;
  const navigate = useNavigate();
  const { setShowDeleteModal, setSystemId, systemId } =
    useContext(TableContext);

  function showDelete() {
    setSystemId(doc?.id);
    setShowDeleteModal(true);
  }

  return (
    <TR>
      <TDT name="Driver" txt={doc?.driver || ""} />
      <TDT name="Vehicle Reg" txt={doc?.reg_number || "--"} />
      <TDT name="Trip Date" txt={doc?.date || "--"} />
      <TDT name="Start Location" txt={doc?.start_location || "--"} />
      <TDT name="End Location" txt={doc?.end_location || "--"} />
      <TDT name="Distance" txt={doc?.distance || "--"} />
      <TDT name="Fuel Consumption" txt={doc?.consumption || "--"} />
      <TDT name="Trip Duration" txt={doc?.duration || "--"} />
      <TDT name="Cargo Details" txt={doc?.cargo_details || "--"} />
      <TDT
        name="ACTION"
        txt={
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`view/${doc?.id}`)}
              >
                <FaEye className="text-blue-500 text-2xl text-center" />
              </p>
            </div>
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
              </p>
            </div>
            <div onClick={() => {}} className="cursor-pointer">
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line
                  className="text-red text-2xl text-center cursor-pointer"
                  onClick={showDelete}
                />
              </p>
            </div>
          </div>
        }
      />
    </TR>
  );
}

export default TableRow;
