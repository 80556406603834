const init_requests = {
  item_name: {
    value: "",
    label: "Item Name",
    placeholder: "Enter item name",
  },
  quantity: {
    value: "",
    label: "Quantity",
    placeholder: "Enter quantity",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Enter description",
  },
};

export default init_requests;
