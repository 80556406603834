import Btn from "./../btn/Btn";
import { GrNext, GrPrevious } from "react-icons/gr";

function Navigation(props) {
  const { pagination = {} } = props;
  const {
    total_docs = 0,
    page = 1,
    pages = 0,
    total = 100,
    hasNextPage = false,
    hasPrevPage = false,
    handleNext = () => {},
    handlePrev = () => {},
  } = pagination;

  const nextBtnClick = () => {
    if (!hasNextPage) return;
    handleNext();
  };

  const prevBtnClick = () => {
    if (!hasPrevPage) return;
    handlePrev();
  };

  return (
    <div className="flex items-center gap-4 w-full md:w-auto">
      <span className="text-gray-600">
        {total_docs} of {total}
      </span>
      <div className="flex items-center gap-x-2">
        <span
          className={`rounded-full ${
            hasPrevPage ? "cursor-pointer" : "opacity-50"
          }`}
        >
          <div
            onClick={prevBtnClick}
            className="p-3 cursor-pointer rounded-full bg-gray-200 hover:bg-gray-300"
          >
            <GrPrevious className="text-lg" />
          </div>
        </span>
        <span className="text-gray-600">
          {page}/{pages}
        </span>
        <span
          className={`rounded-full ${
            hasNextPage ? "cursor-pointer" : "opacity-50"
          }`}
        >
          <div
            onClick={nextBtnClick}
            className="p-3 cursor-pointer rounded-full bg-gray-200 hover:bg-gray-300"
          >
            <GrNext className="text-lg  " />
          </div>
        </span>
      </div>
    </div>
  );
}

export default Navigation;
