import { TR, TDT, TA, TD } from "../../components";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import { convDate3 } from "../../../../Util/Time";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowDeleteModal, setSystemId } = useContext(TableContext);

  function showDelete() {
    setSystemId(doc?.id);
    setShowDeleteModal(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }
  return (
    <TR>
      <TDT name="Vehicle Reg" txt={doc?.vehicles?.plate_no || "--"} />
      <TDT name="Name" txt={doc?.name || "--"} />
      <TDT
        name="Last Maintenance Date"
        txt={convDate3(doc?.last_maintenance_date) || "--"}
      />
      {/* <TDT name="Requested By" txt={doc?.requested_by || "--"} /> */}
      <TDT name="Maintenance Cost" txt={doc?.maintenance_cost || "--"} />
      <TDT name="Performed By" txt={doc?.performed_by || "--"} />
      <TDT
        name="Actual Maintenance Date"
        txt={convDate3(doc?.actual_maintenance_date) || "--"}
      />
      <TDT
        name="Next Maintenance Date"
        txt={convDate3(doc?.next_maintenance_date) || "--"}
      />
      <TDT name="Description" txt={doc?.description || "--"} />

      <TDT
        name="Actions"
        txt={
          <div className="flex gap-4">
            {/* <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`view/${doc?.id}`)}
              >
                <FaEye className="text-blue-500 text-2xl text-center" />
              </p>
            </div> */}
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
              </p>
            </div>
            <div
              onClick={
                hasOtherPermission(user, "delete_maintenance_record")
                  ? showDelete
                  : handleAuth
              }
              className="cursor-pointer"
            >
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line className="text-red text-2xl text-center cursor-pointer" />
              </p>
            </div>
          </div>
        }
      />
    </TR>
  );
}

export default TableRow;
