import React from "react";
import AddOrder from "../../../Features/Forms/POs/Add";

const Add = () => {
  return (
    <div>
      <AddOrder />
    </div>
  );
};

export default Add;
