import { useEffect, useState } from "react";
import useAxios from "../../../../Hooks/useAxios";

const initDoc = [];

function useTable() {
  const [limit, setLimit] = useState(10);
  const [docs, setDocs] = useState(initDoc);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const request = useAxios();

  // Pagination
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const [order, setOrder] = useState("id-desc");

  const refetchDocs = () => getDocs({ page: page, limit: limit });

  const [systemId, setSystemId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getDocs({ page, limit });
  }, [page, limit, order]);

  async function getDocs({ page, limit }) {
    let res = await request({
      method: "GET",
      url: "vehicles/all-vehicles",
      params: {
        page,
        limit,
        order,
      },
      show_loading: false,
      loadingFunc: setIsLoading,
    });
    if (res === "error") {
      return;
    }

    setTotal(res?.pagination?.total || 0);
    setPages(res?.pagination?.pages || 0);
    setHasNextPage(res?.pagination?.hasNextPage || false);
    setHasPrevPage(res?.pagination?.hasPrevPage || false);
    setDocs(res?.docs || initDoc);
  }

  async function clearFilters() {
    setIsFiltered(false);
  }

  function handleNext() {
    if (hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  function handlePrev() {
    if (hasPrevPage) {
      setPage((prevPage) => prevPage - 1);
    }
  }

  async function handleDelete(id) {
    let res = await request({
      method: "DELETE",
      url: "vehicles/delete",
      params: {
        id: parseInt(id),
      },
    });

    if (res !== "error") {
      setShowDeleteModal(false);
      refetchDocs();
    }
  }

  return {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination: {
      page,
      total,
      pages,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      limit,
      setLimit,
      total_docs: docs.length || 0,
    },
    setOrder,
    clearFilters,
    systemId,
    setSystemId,
    refetchDocs,
    showDeleteModal,
    setShowDeleteModal,
    handleDelete,
  };
}

export default useTable;
