const initDoc = {
  vehicle_id: {
    value: "",
    label: "Vehicle Reg",
    placeholder: "Enter Vehicle Reg",
  },
  name: {
    value: "",
    label: "Name",
    placeholder: "Enter Name",
  },
  last_maintenance_date: {
    value: "",
    label: "Last Maintenance Date",
    placeholder: "Pick Last Maintenance Date",
  },
  requested_by: {
    value: "",
    label: "Requested By",
    placeholder: "Enter Requester's Name",
  },
  maintenance_cost: {
    value: "",
    label: "Maintenance Cost",
    placeholder: "Enter Maintenance Cost",
  },
  performed_by: {
    value: "",
    label: "Performed By",
    placeholder: "Enter Person Who Performed Maintenance",
  },
  actual_maintenance_date: {
    value: "",
    label: "Actual Maintenance Date",
    placeholder: "Pick Actual Maintenance Date",
  },
  next_maintenance_date: {
    value: "",
    label: "Next Maintenance Date",
    placeholder: "Pick Next Maintenance Date",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Enter Description",
  },
};

export default initDoc;


