import { TR, TDT, TA, TD } from "../../components";
import { FaCheckCircle } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const { setShowApproveModal, setShowCancelModal, setRequestId } =
    useContext(TableContext);

  function showApprove() {
    setRequestId(doc?.id);
    setShowApproveModal(true);
  }
  function showCancel() {
    setRequestId(doc?.id);
    setShowCancelModal(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }

  const navigate = useNavigate();

  return (
    <TR>
      <TDT name="ITEM NAME" txt={doc?.name || ""} />
      <TDT name="QUANTITY" txt={doc?.quantity || "--"} />
      <TDT name="Description" txt={doc?.description || "--"} />
      <TDT
        name="STATUS"
        txt={
          <div
            className={`rounded-2xl w-fit px-4 ${
              doc?.status === "pending"
                ? "bg-red"
                : doc?.status === "approved"
                ? "bg-green"
                : "bg-secondary"
            } `}
          >
            <p className={`text-md text-white`}>{doc?.status || "--"}</p>
          </div>
        }
      />
      <TA name="ACTIONS" id={doc?.id}>
        <div className="flex flex-col gap-4 w-fit">
          {doc?.status === "pending" && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`edit/${doc?.id}`)}
            >
              <p className="text-center text-xl flex gap-2">
                <FiEdit2 className="text-blue-500 text-2xl text-center" />
                <span>Edit</span>
              </p>
            </div>
          )}
          {doc?.status !== "approved" && doc?.status !== "cancelled" && (
            <div
              className="cursor-pointer"
              onClick={
                hasOtherPermission(user, "approve_request")
                  ? showApprove
                  : handleAuth
              }
            >
              <p className="text-center text-xl flex gap-2">
                <FaCheckCircle className="text-green-500 text-2xl text-center" />
                <span>Approve</span>
              </p>
            </div>
          )}
          {doc?.status !== "cancelled" && (
            <div
              className="cursor-pointer"
              onClick={
                hasOtherPermission(user, "cancel_request")
                  ? showCancel
                  : handleAuth
              }
            >
              <p className="text-center text-xl flex gap-2">
                <MdCancel className="text-2xl text-center text-red" />
                <span>Cancel</span>
              </p>
            </div>
          )}
        </div>
      </TA>
    </TR>
  );
}

export default TableRow;
