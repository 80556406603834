const init_devices = {
  FAssetID: {
    value: "",
    label: "Device ID",
    placeholder: "Device ID",
  },
  FIMEI: {
    value: "",
    label: "IMEI",
    placeholder: "IMEI ID",
  },
  FAssetTypeID: {
    value: "",
    label: "Asset Type ID",
    placeholder: "Asset Type ID",
  },
  FSIMNumber: {
    value: "",
    label: "Sim Card No",
    placeholder: "Sim Card No",
  },
  FVehicleName: {
    value: "",
    label: "Vehicle Reg",
    placeholder: "Vehicle Reg",
  },
  FDescription: {
    value: "",
    label: "Description",
    placeholder: "Description",
  },
};

export default init_devices;
