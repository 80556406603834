import useApp from "../../Hooks/useApp";

function MainSection(props) {
  const { children } = props;
  const { sideBarOpen, device } = useApp();

  return (
    <div
      className={`w-full h-full flex-1 overflow-y-auto transition-transform duration-300 ${
        sideBarOpen && device !== "sm" ? "ml-[250px]" : ""
      }`}
      style={{ zIndex: 10 }}
    >
      {children}
    </div>
  );
}

export default MainSection;
