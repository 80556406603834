import React from "react";
import AddInventory from "../../../Features/Forms/Inventory/Add";

const Add = () => {
  return (
    <div>
      <AddInventory />
    </div>
  );
};

export default Add;
