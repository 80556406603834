import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import TabBar from "../../../../../Components/TabBar";
import FleetHistory from "../../../../../Features/Tables/FleetHistory";
import FuelManagement from "../../../../../Features/Tables/Vehicle_FuelMgt";
import Maintenance from "../../../../../Features/Tables/MaintenanceLogs";
import { useParams } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import {
  FaWrench,
  FaGasPump,
  FaRoute,
  FaCar,
  FaClipboard,
  FaMoneyBillWave,
  FaTachometerAlt,
  FaTag,
  FaTrailer,
} from "react-icons/fa";

const containerStyle = {
  width: "100%",
  height: "45vh",
};

const center = { lat: -1.286389, lng: 36.817223 };

const mapKey = process.env.REACT_APP_MAP_KEY;

function Index() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapKey,
  });

  const request = useAxios();
  const { id } = useParams();
  const [vehicle, setVehicle] = useState({});

  useEffect(() => {
    getVehicle();
  }, []);

  const getVehicle = async () => {
    const res = await request({
      method: "GET",
      url: "vehicles/single-vehicle",
      params: { id: parseInt(id) },
      show_loading: false,
    });
    if (res !== "error") {
      setVehicle(res);
    }
  };

  const [map, setMap] = useState(null);
  const [mapType, setMapType] = useState("roadmap");

  const onLoad = useCallback((mapInstance) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    mapInstance.fitBounds(bounds);
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMapTypeChange = (type) => {
    setMapType(type);
    if (map) {
      map.setMapTypeId(type);
    }
  };

  const [active, setActive] = useState("1");
  const tabList = [
    { id: "1", value: "Maintenance Logs", icon: FaWrench },
    { id: "2", value: "Fuel History", icon: FaGasPump },
    { id: "3", value: "Trip History", icon: FaRoute },
  ];

  return isLoaded ? (
    <div className="relative">
      <div className="flex flex-col md:flex-row space-x-4">
        {/* Vehicle Info Card */}
        <div
          className="w-full md:w-1/3 p-6 bg-gradient-to-r from-light_blue to-light_cyan text-dark-gun-metal rounded-lg shadow-xl transition-transform transform"
          style={{ position: "relative" }}
        >
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-center">
            Vehicle Information
          </h2>
          <div className="h-1 w-1/2 bg-secondary mx-auto mb-6"></div>

          <div className="grid grid-cols-2 gap-y-4 text-left px-4">
            {[
              {
                icon: <FaCar className="text-lg md:text-xl text-primary" />,
                label: "Registration No:",
                value: vehicle?.plate_no,
              },
              {
                icon: (
                  <FaClipboard className="text-lg md:text-xl text-primary" />
                ),
                label: "Model:",
                value: vehicle?.make,
              },
              {
                icon: <FaTag className="text-lg md:text-xl text-primary" />,
                label: "VIN:",
                value: vehicle?.vin,
              },
              {
                icon: <FaGasPump className="text-lg md:text-xl text-primary" />,
                label: "Fuel Capacity:",
                value: vehicle?.fuel_capacity,
              },
              {
                icon: (
                  <FaMoneyBillWave className="text-lg md:text-xl text-primary" />
                ),
                label: "Revenue:",
                value: vehicle?.revenue,
              },
              {
                icon: (
                  <FaTachometerAlt className="text-lg md:text-xl text-primary" />
                ),
                label: "Mileage:",
                value: vehicle?.mileage,
              },
              {
                icon: <FaTag className="text-lg md:text-xl text-primary" />,
                label: "Fleet Number:",
                value: vehicle?.fleet_no,
              },
              {
                icon: <FaTrailer className="text-lg md:text-xl text-primary" />,
                label: "Trailer Number:",
                value: vehicle?.trailer_no,
              },
            ].map((item, index) => (
              <React.Fragment key={index}>
                {/* Icon and Label Section */}
                <div className="flex items-center space-x-2">
                  {item.icon}
                  <span className="font-semibold text-sm">{item.label}</span>
                </div>
                {/* Value Section */}
                <p className="text-sm text-gray-700">{item.value || "--"}</p>
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Google Map */}
        <div className="md:w-2/3 w-full relative rounded-lg overflow-hidden shadow-lg border border-gray-200">
          <div className="absolute top-2 right-14 z-10 flex space-x-2">
            {["roadmap", "satellite", "hybrid", "terrain"].map((type) => (
              <button
                key={type}
                onClick={() => handleMapTypeChange(type)}
                className={`px-4 py-2 rounded-lg transition duration-300 ${
                  mapType === type
                    ? "bg-secondary text-white"
                    : "bg-white text-primary border border-gray-300 hover:bg-gray-100"
                }`}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapTypeId={mapType}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
            }}
          />
        </div>
      </div>

      <div className="bg-cream-white rounded-lg shadow-md p-4 mt-5">
        <TabBar list={tabList} active={active} setActive={setActive} />
        {active === "1" && <Maintenance />}
        {active === "2" && <FuelManagement />}
        {active === "3" && <FleetHistory />}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(Index);
