import TableHead from "./Table/TableHead";

import useTable from "./Provider/useTable";
import TableRow from "./Table/TableRow";

import { Table, TBody } from "../components";

import Filters from "./Filter";
import { useNavigate } from "react-router-dom";
import TableContext from "./Provider/TableContext";

function Students({ selectedDevice, setSelectedDevice }) {
  const {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination,
    genders,
    dobRange,
    setDobRange,
    selectedGenders,
    setSelectedGenders,
    doaRange,
    setDoaRange,
    setOrder,
    clearFilters,
  } = useTable();

  const navigate = useNavigate();

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        docs,
        setDocs,
        isLoading,
        setIsLoading,
        isFiltered,
        setIsFiltered,
        pagination,
        genders,
        dobRange,
        setDobRange,
        selectedGenders,
        setSelectedGenders,
        doaRange,
        setDoaRange,
        setOrder,
        clearFilters,
      }}
    >
      <Table
        label="Vehicles"
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setDocs={setDocs}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
        handleRemoveFilters={clearFilters}
        pagination={pagination}
        url="vehicles/search-vehicle"
      >
        <TableHead setOrder={setOrder} />
        <TBody>
          {docs.map((doc, i) => {
            return (
              <TableRow
                key={doc?.id || i}
                doc={doc}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
              />
            );
          })}
        </TBody>
      </Table>
    </TableContext.Provider>
  );
}

export default Students;
