import React from "react";
import NavBtn from "../NavBtn";
import { MdCellWifi } from "react-icons/md";

const Devices = (props) => {
  const {
    exactPath = "/dashboard/devices",
    to = "/dashboard/devices",
    partialPath = "/devices",
    label = "Devices",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={MdCellWifi}
    />
  );
};

export default Devices;