import React from "react";
import AddWarehouse from '../../../Features/Forms/Warehouses/Add'

const Add = () => {
  return <div>
    <AddWarehouse />
  </div>;
};

export default Add;
