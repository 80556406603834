import React, { useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useEffect } from "react";
import useAxios from "../../Hooks/useAxios";
import { hasGraphPermission, hasCardPermission } from "../../Util/Permissions";
import useApp from "../../Hooks/useApp";

const FleetDashboard = () => {
  const request = useAxios();
  const { user } = useApp();

  const [image, setImage] = useState("");

  const fuelData = [
    { name: "Vehicle 1", fuelUsage: 75 },
    { name: "Vehicle 2", fuelUsage: 60 },
    { name: "Vehicle 3", fuelUsage: 90 },
  ];

  const distanceData = [
    { name: "Jan", distance: 400 },
    { name: "Feb", distance: 800 },
    { name: "Mar", distance: 600 },
    { name: "Apr", distance: 1200 },
    { name: "May", distance: 900 },
  ];

  const statusData = [
    { name: "In Transit", value: 3 },
    { name: "Available", value: 5 },
    { name: "Maintenance", value: 2 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  return (
    <div className="p-8 bg-light_grey">
      <h1 className="text-4xl font-bold mb-8 text-primary">Fleet Dashboard</h1>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {hasCardPermission(user, "total_fuel_usage") && (
          <div className="bg-neutral-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">Total Fuel Usage</h2>
            <p className="text-3xl font-bold text-green">2250 L</p>
          </div>
        )}
        {hasCardPermission(user, "total_fuel_cost") && (
          <div className="bg-neutral-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">Fuel Cost</h2>
            <p className="text-3xl font-bold text-secondary">$2,500</p>
          </div>
        )}
        {hasCardPermission(user, "total_scheduled_maintenances") && (
          <div className="bg-neutral-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">
              Scheduled Maintenance
            </h2>
            <p className="text-3xl font-bold text-yellow">2 Vehicles</p>
          </div>
        )}
        {hasCardPermission(user, "total_upcoming_dispatches") && (
          <div className="bg-neutral-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">Upcoming Dispatches</h2>
            <p className="text-3xl font-bold text-red">5 Dispatches</p>
          </div>
        )}
      </div>

      {/* Fuel Usage Analytics */}
      {hasGraphPermission(user, "fuel_usage_analytics") && (
        <div className="mb-8 bg-white shadow-md rounded-lg p-5">
          <h2 className="text-2xl font-semibold mb-4 text-primary">
            Fuel Usage Analytics
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={fuelData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis dataKey="name" stroke="#667080" />
              <YAxis stroke="#667080" />
              <Tooltip />
              <Bar dataKey="fuelUsage" fill="#154469" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Vehicle Status Breakdown */}
      {hasGraphPermission(user, "vehicle_status_breakdown") && (
        <div className="mb-8 bg-white shadow-md rounded-lg p-5">
          <h2 className="text-2xl font-semibold mb-4 text-primary">
            Vehicle Status Breakdown
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={statusData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) =>
                  `${name}: ${(percent * 100).toFixed(0)}%`
                }
                outerRadius={100}
                dataKey="value"
              >
                {statusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Distance Traveled Over Time */}
      {hasGraphPermission(user, "distance_traveled_over_time") && (
        <div className="mb-8 bg-white shadow-md rounded-lg p-5">
          <h2 className="text-2xl font-semibold mb-4 text-primary">
            Distance Traveled Over Time
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={distanceData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis dataKey="name" stroke="#667080" />
              <YAxis stroke="#667080" />
              <Tooltip />
              <Line type="monotone" dataKey="distance" stroke="#4CAF50" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default FleetDashboard;
