import React from "react";
import FuelTrajectoryGraph from "./Trajectory";

const Index = () => {
  return (
    <div>
      <FuelTrajectoryGraph />
    </div>
  );
};

export default Index;
