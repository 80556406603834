import React, { useContext } from "react";
import AppContext from "../Provider/AppContext";

function useApp() {
  const {
    device,
    sideBarOpen,
    setSideBarOpen,
    requestErrorModal,
    requestSuccessModal,
    loadingModal,
    user,
    setUser,
    token,
    setToken,
    showNotAuthorized,
    setShowNotAuthorized,
  } = useContext(AppContext);

  return {
    device,
    sideBarOpen,
    setSideBarOpen,
    requestErrorModal,
    requestSuccessModal,
    loadingModal,
    user,
    setUser,
    token,
    setToken,
    showNotAuthorized,
    setShowNotAuthorized,
  };
}

export default useApp;
