import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="REQUEST ID" field="request_id" setOrder={setOrder} />
      <THTS txt="Item Name" field="name" setOrder={setOrder} />
      <THTS txt="Quantity" field="quantity" setOrder={setOrder} />
      <THTS txt="Total Amount" field="total_amount" setOrder={setOrder} />
      <THTS txt="Description" field="description" setOrder={setOrder} />
      <THTS txt="Approved By" field="approved_by" setOrder={setOrder} />
      <THTS txt="Created By" field="created_by" setOrder={setOrder} />
      <THTS txt="Status" field="status" setOrder={setOrder} />
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
