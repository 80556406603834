function BtnMd({ click = () => {}, label = "Button" }) {
  return (
    <button
      onClick={click}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      {label}
    </button>
  );
}

export default BtnMd;
