import React, { useEffect, useState } from "react";
import useAxios from "../../../../Hooks/useAxios";
import { BiChevronDown } from "react-icons/bi";

const Permissions = ({ config, onConfigChange }) => {
  const [screen, setScreen] = useState({});
  const [card, setCard] = useState({});
  const [graph, setGraph] = useState({});
  const [table, setTable] = useState({});
  const [form, setForm] = useState({});
  const [others, setOthers] = useState({});
  const [openSection, setOpenSection] = useState(null);

  const request = useAxios();

  useEffect(() => {
    getConfig();
  }, []);

  async function getConfig() {
    const res = await request({
      method: "GET",
      url: "roles/config",
      show_loading: false,
      show_error: false,
    });

    if (res !== "error") {
      setScreen(res?.screen);
      setCard(res?.card);
      setTable(res?.table);
      setForm(res?.form);
      setGraph(res?.graph);
      setOthers(res?.others);
    }
  }

  const toggleSection = (name) => {
    setOpenSection((prev) => (prev === name ? null : name));
  };

  const handleCheckboxChange = (sectionName, setSection, itemKey) => {
    setSection((prev) => {
      const updatedSection = {
        ...prev,
        [itemKey]: {
          ...prev[itemKey],
          is_allowed: !prev[itemKey].is_allowed,
        },
      };

      onConfigChange({
        screen,
        card,
        graph,
        table,
        form,
        others,
        [sectionName]: updatedSection,
      });

      return updatedSection;
    });
  };

  const renderContent = (name, content, setSection) => {
    const contentArray = Object.entries(content);

    return (
      openSection === name && (
        <div className="mt-2 p-2 border rounded">
          {contentArray.map(([key, item]) => (
            <div key={key} className="flex items-center justify-between p-2">
              <div>
                <h4 className="font-medium capitalize">{item.name}</h4>
                <p className="text-sm text-gray-500">{item.description}</p>
              </div>
              <input
                type="checkbox"
                className="w-6 h-6 cursor-pointer"
                checked={item.is_allowed}
                onChange={() => handleCheckboxChange(name, setSection, key)}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className="bg-white p-5 border rounded-md mt-5 mb-5">
      <h2 className="text-center font-semibold text-primary">
        Role-Based Access Control Configuration
      </h2>
      <div className="mt-5 p-4 space-y-6">
        {/* Render each section separately */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Screens</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "screen" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("screen")}
            />
          </div>
          {renderContent("screen", screen, setScreen)}
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Tables</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "table" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("table")}
            />
          </div>
          {renderContent("table", table, setTable)}
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Cards</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "card" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("card")}
            />
          </div>
          {renderContent("card", card, setCard)}
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Graphs</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "graph" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("graph")}
            />
          </div>
          {renderContent("graph", graph, setGraph)}
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Forms</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "form" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("form")}
            />
          </div>
          {renderContent("form", form, setForm)}
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase">Others</h3>
            <BiChevronDown
              className={`font-bold text-2xl cursor-pointer ${
                openSection === "others" ? "rotate-180" : ""
              } transition-transform`}
              onClick={() => toggleSection("others")}
            />
          </div>
          {renderContent("others", others, setOthers)}
        </div>
      </div>
    </div>
  );
};

export default Permissions;
