import React, { useState } from "react";
import Vehicles from './components/RouteVehicles'
import VehicleContext from "../context/VehicleContext";

const Index = () => {

  return (
    <VehicleContext.Provider>

  
      <div className="mt-4">
        <Vehicles />
      </div>
    </VehicleContext.Provider>
  );
};

export default Index;