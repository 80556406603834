import React from "react";
import NavBtn from "../NavBtn";
import { FaDollarSign } from "react-icons/fa";

const Finance = (props) => {
  const {
    exactPath = "/dashboard/finance",
    to = "/dashboard/finance",
    partialPath = "/finance",
    label = "Finance",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaDollarSign}
    />
  );
};

export default Finance;
