import React from "react";
import NavBtn from "../NavBtn";
import { FaRoute } from "react-icons/fa6";

const FleetRoutes = (props) => {
  const {
    exactPath = "/dashboard/routes",
    to = "/dashboard/routes",
    partialPath = "/routes",
    label = "Fleet Routes",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaRoute}
    />
  );
};

export default FleetRoutes;