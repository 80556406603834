import React from "react";
import { IoSettingsOutline } from "react-icons/io5";

function Settings() {
  return (
    <div className="p-2 rounded-full border">
      <div className="relative">
        <IoSettingsOutline className="text-3xl" />
      </div>
    </div>
  );
}

export default Settings;
