import React from "react";
import AddFleet from '../../../Features/Forms/FleetRoutes/Add'

const Add = () => {
  return <div>
    <AddFleet />
  </div>;
};

export default Add;
