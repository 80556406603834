import React from "react";
import AddRequest from "../../../Features/Forms/Requests/Add";

const Add = () => {
  return (
    <div>
      <AddRequest />
    </div>
  );
};

export default Add;
