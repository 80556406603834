import React, { useState } from "react";
import BasicModal from "./BasicModal";
import Cropper from "react-easy-crop";
import BtnMd from '../../Btn/BtnMd'


function ImageModal(props) {
  const {
    input,
    field,
    setInput,
    rounded = false,
    aspect = 1,
    showModal,
    setShowModal,
    cropHeight,
    cropWidth,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropix, setCropPix] = useState({});

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCropPix(croppedAreaPixels);
  };

  async function handleCrop() {
    if (input[field]?.src === null) {
      return;
    }
    const image = new Image();
    image.src = input[field].src;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropix.width;
    canvas.height = cropix.height;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        cropix.x * scaleX,
        cropix.y * scaleY,
        cropix.width * scaleX,
        cropix.height * scaleY,
        0,
        0,
        cropix.width,
        cropix.height
      );

      canvas.toBlob(async (blob) => {
        if (blob == null) return;
        const croppedImageFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        const inputCpy = { ...input };
        const curentInput = inputCpy[field];

        const base64 = await blobToBase64(blob);

        curentInput.croppedFile = croppedImageFile;
        curentInput.cropSrc = URL.createObjectURL(croppedImageFile);

        curentInput.base64 = base64;
        setInput(inputCpy);

        setShowModal(false);
      }, "image/jpeg,image/png");
    }
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div style={{ width: "80vw", height: "80vh" }}>
        <div className="relative" style={{ width: "auto", height: "90%" }}>
          {!cropWidth || !cropHeight ? (
            <Cropper
              image={input[field]?.src}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape={rounded ? "round" : "rect"}
            />
          ) : (
            <Cropper
              image={input[field]?.src}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropSize={{ width: cropWidth, height: cropHeight }}
              cropShape={rounded ? "round" : "rect"}
            />
          )}
        </div>
        <div className="w-full flex items-center justify-center gap-x-4  py-2 mt-2">
          <input
            id="minmax-range"
            type="range"
            min="0"
            max="5"
            step={0.1}
            className="h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 w-full md:w-2/3"
            onChange={(e) => setZoom(parseInt(e?.target?.value || "0"))}
          />
        </div>
        <div className=" flex w-full justify-center mt-2 mb-4">
          <BtnMd label="Submit" click={handleCrop} />
        </div>
      </div>
    </BasicModal>
  );
}

export default ImageModal;
