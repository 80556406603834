import React from "react";
import Logistics from '../../../Features/Tables/Logistics'

const All = () => {
  return <div>
    <Logistics />
  </div>;
};

export default All;
