import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Vehicle" field="vehicle_id" setOrder={setOrder} />
      <THTS txt="Route" field="route_id" setOrder={setOrder} />
      <THTS txt="Departure Point" field="departure_point" setOrder={setOrder} />
      <THTS txt="Destination" field="destination" setOrder={setOrder} />
      <THTS txt="Departure Date" field="departure_date" setOrder={setOrder} />
      <THTS txt="Actual Arrival Date" field="actual_arrival_date" setOrder={setOrder} />
      <THTS txt="Expected Arrival Date" field="expected_arrival_date" setOrder={setOrder} />
      <THTS txt="Scheduled By" field="scheduled_by" setOrder={setOrder} />
      <THTS txt="Status" field="trip_status_id" setOrder={setOrder} />
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
