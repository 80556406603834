import useApp from "../../Hooks/useApp";

import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import { HiOutlineMenu, HiOutlineMenuAlt2 } from "react-icons/hi";

function MenuBtn() {
  return (
    <>
      <LgBtn />
      <SmBtn />
    </>
  );
}

function LgBtn() {
  const { device, sideBarOpen, setSideBarOpen } = useApp();

  if (device === "sm") {
    return null;
  }

  return (
    <span
      className=" p-4 rounded-r-full text-4xl hover:text-primary shadow-md cursor-pointer"
      onClick={() => setSideBarOpen((c) => !c)}
    >
      {sideBarOpen ? (
        <AiOutlineMenuFold className="text-xl" />
      ) : (
        <AiOutlineMenuUnfold className="text-xl" />
      )}
    </span>
  );
}

function SmBtn() {
  const { device, sideBarOpen, setSideBarOpen } = useApp();

  if (device !== "sm") {
    return null;
  }

  return (
    <span
      className="p-4 rounded-r-full text-4xl hover:text-primary shadow-md cursor-pointer"
      onClick={() => setSideBarOpen((c) => !c)}
    >
      {sideBarOpen ? (
        <HiOutlineMenuAlt2 className="text-xl" />
      ) : (
        <HiOutlineMenu className="text-xl" />
      )}
    </span>
  );
}

export default MenuBtn;