import React from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../Components/Buttons/Btn";

const NotAuthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red mb-4">Not Authorized</h1>
      <p className="text-lg text-gray-700 mb-6">
        You do not have permission to access this page.
      </p>
      <Btn
        onClick={handleGoBack}
        // className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
      >
        Go Back
      </Btn>
    </div>
  );
};

export default NotAuthorized;
