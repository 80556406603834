import React from "react";
import CompanyDetails from "./CompanyDetails";

const Index = () => {
  return (
    <div className="bg-white rounded-lg p-4 mt-5 mr-4 mb-5">
      <CompanyDetails />
    </div>
  );
};

export default Index;

