import { TR, TDT, TA, TD } from "../../components";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";


function TableRow(props) {
  const { doc } = props;
  const navigate = useNavigate();
  const {setSystemId, systemId } =
    useContext(TableContext);

 

 

  return (
    <TR>
      <TDT name="User ID" txt={doc?.userId || "--"} />
      <TDT name="Timestamp" txt={doc?.timestamp || "--"} />
      <TDT name="Action" txt={doc?.action || "--"} />
      <TDT name="Location" txt={doc?.location || "--"} />
      <TDT name="Status" txt={doc?.status || "--"} />
     
      </TR>
  );
}

export default TableRow;
