import React from "react";
import NavBtn from "../NavBtn";
import { TbCalendarUser } from "react-icons/tb";

const Roles = (props) => {
  const {
    exactPath = "/dashboard/roles",
    to = "/dashboard/roles",
    partialPath = "/roles",
    label = "Roles",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={TbCalendarUser}
    />
  );
};

export default Roles;
