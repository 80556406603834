const init_devices = {
  name: {
    value: "",
    label: "Name",
    placeholder: "Warehouse name",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Description",
  },
  location: {
    value: "",
    label: "Location",
    placeholder: "Location",
  },
 };

export default init_devices;
