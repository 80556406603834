import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Device ID" field="FAssetID" setOrder={setOrder} />
      <THTS txt="Asset Type ID" field="FAssetTypeID" setOrder={setOrder} />
      <THTS txt="Sim Card No" field="FSIMNumber" setOrder={setOrder} />
      <THTS txt="IMEI" field="FIMEI" setOrder={setOrder} />
      <THTS txt="Description" field="FDescription" setOrder={setOrder} />
      
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
