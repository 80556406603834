import { useContext } from "react";
import { Thead, THT, THTS } from "../../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Driver" field="driver" setOrder={setOrder} />
      <THTS txt="Vehicle Reg" field="reg_number" setOrder={setOrder} />
      <THTS txt="Trip Date" field="date" setOrder={setOrder} />
      <THTS txt="Start Location" field="start_location" setOrder={setOrder} />
      <THTS txt="End Location" field="end_location" setOrder={setOrder} />
      <THTS txt="Distance" field="distance" setOrder={setOrder} />
      <THTS txt="Fuel Consumption" field="consumption" setOrder={setOrder} />
      <THTS txt="Trip Duration" field="duration" setOrder={setOrder} />
      <THTS txt="Cargo Details " field="cargo_details" setOrder={setOrder} />
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
