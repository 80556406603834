import React from "react";
import NavBtn from "../NavBtn";
import { FaClipboardList } from "react-icons/fa";

const Orders = (props) => {
  const {
    exactPath = "/dashboard/purchase-orders",
    to = "/dashboard/purchase-orders",
    partialPath = "/purchase-orders",
    label = "Purchase Orders",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaClipboardList}
    />
  );
};

export default Orders;
