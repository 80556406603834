import React from "react";
import EditRole from "../../../Features/Forms/Roles/Edit";

const Edit = () => {
  return (
    <div>
      <EditRole />
    </div>
  );
};

export default Edit;
