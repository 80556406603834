import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import useMapLoader from "../../../../../Hooks/useMapLoader";

const containerStyleSmall = {
  width: "300px",
  height: "200px",
  margin: "10px",
};

const containerStyleFull = {
  width: "100%",
  height: "100vh",
};

function Monitoring() {
  const mapKey = process.env.REACT_APP_MAP_KEY;
  const isLoaded = useMapLoader(mapKey);

  const assets = [
    {
      id: "7500313618",
      serial_no: "SNL7500313618",
      vehicle_plate: "KCA123A",
      lat: -1.286389, // Nairobi coordinates
      lng: 36.817223,
      speed: 0,
      mileage: 0,
      gpsTime: "2020-08-12T01:56:16Z",
      recvTime: "2020-08-12T01:56:31.036Z",
    },
    {
      id: "1018100169",
      serial_no: "SNL1018100169",
      vehicle_plate: "KCD456B",
      lat: -4.043477, // Mombasa coordinates
      lng: 39.668206,
      speed: 28.2,
      mileage: 120,
      gpsTime: "2020-08-12T01:29:29Z",
      recvTime: "2020-08-12T01:34:30Z",
    },
    {
      id: "1023101220",
      serial_no: "SNL1023101220",
      vehicle_plate: "KCE789C",
      lat: 0.516664, // Kisumu coordinates
      lng: 35.282788,
      speed: 40,
      mileage: 300,
      gpsTime: "2020-08-12T03:10:29Z",
      recvTime: "2020-08-12T03:20:30Z",
    },
    {
      id: "1034101345",
      serial_no: "SNL1034101345",
      vehicle_plate: "KCF890D",
      lat: -0.091702, // Nakuru coordinates
      lng: 36.68299,
      speed: 35,
      mileage: 250,
      gpsTime: "2020-08-12T04:15:29Z",
      recvTime: "2020-08-12T04:25:30Z",
    },
    {
      id: "1045101456",
      serial_no: "SNL1045101456",
      vehicle_plate: "KCG123E",
      lat: 3.938055, // Mandera coordinates
      lng: 41.867249,
      speed: 50,
      mileage: 400,
      gpsTime: "2020-08-12T05:15:29Z",
      recvTime: "2020-08-12T05:25:30Z",
    },
  ];

  const [expandedMap, setExpandedMap] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Filter assets based on search query
  const filteredAssets = assets.filter(
    (asset) =>
      asset.serial_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
      asset.vehicle_plate.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleMarkerClick = (asset) => {
    setSelectedAsset(asset);
  };

  const closeFloatingCard = () => setSelectedAsset(null);

  const expandMap = (id) => setExpandedMap(id);
  const minimizeMap = () => setExpandedMap(null);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Serial No or Vehicle Plate..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>

      <div className="flex flex-wrap">
        {expandedMap
          ? // Fullscreen map
            assets
              .filter((asset) => asset.id === expandedMap)
              .map((asset) => (
                <div key={asset.id} className="relative w-full">
                  <GoogleMap
                    mapContainerStyle={containerStyleFull}
                    center={{ lat: asset.lat, lng: asset.lng }}
                    zoom={15}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                    }}
                  >
                    <Marker
                      position={{ lat: asset.lat, lng: asset.lng }}
                      onClick={() => handleMarkerClick(asset)}
                    />
                  </GoogleMap>
                  <button
                    onClick={minimizeMap}
                    className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Minimize Map
                  </button>
                </div>
              ))
          : // Grid of small maps
            filteredAssets.map((asset) => (
              <div key={asset.id} className="relative">
                <GoogleMap
                  mapContainerStyle={containerStyleSmall}
                  center={{ lat: asset.lat, lng: asset.lng }}
                  zoom={14}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                  }}
                >
                  <Marker
                    position={{ lat: asset.lat, lng: asset.lng }}
                    onClick={() => handleMarkerClick(asset)}
                  />
                </GoogleMap>
                <div className="text-center mt-2 text-sm">
                  <p>Serial No: {asset.serial_no}</p>
                  <p>Plate: {asset.vehicle_plate}</p>
                </div>
                <button
                  onClick={() => expandMap(asset.id)}
                  className="absolute bottom-2 right-2 bg-primary text-white px-3 py-1 rounded-sm"
                >
                  Expand
                </button>
              </div>
            ))}
        {/* Floating card for marker details */}
        {selectedAsset && (
          <div className="absolute top-20 right-4 bg-white shadow-lg p-4 rounded-lg z-50">
            <h3>Asset ID: {selectedAsset.id}</h3>
            <p>Serial No: {selectedAsset.serial_no}</p>
            <p>Vehicle Plate: {selectedAsset.vehicle_plate}</p>
            <p>Speed: {selectedAsset.speed} km/h</p>
            <p>Mileage: {selectedAsset.mileage} km</p>
            <p>GPS Time: {new Date(selectedAsset.gpsTime).toLocaleString()}</p>
            <p>
              Received Time: {new Date(selectedAsset.recvTime).toLocaleString()}
            </p>
            <button
              onClick={closeFloatingCard}
              className="mt-4 bg-gray-500 text-white px-3 py-1 rounded"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(Monitoring);
