// button styles here

const className =
  "rounded flex items-center gap-2 align-middle justify-center text-base font-medium ";

const btnClassInitial = className;

const outlineStyle = "border border-primary-color active:bg-opacity-5";

const btnClass = {
  primary: {
    // outline boolean
    false: `${btnClassInitial} 
    w-auto w-auto text-white bg-primary`,

    true: `${btnClassInitial} ${outlineStyle} 
   bg-clip-text bg-primary active:opacity-70`,
  },

  muted: {
    // outline boolean
    false: `${btnClassInitial}  
  text-old-silver text-base bg-light_grey`,
    true: `${btnClassInitial} ${outlineStyle} 
  text-old-silver bg-light_grey active:bg-opacity-80`,
  },

  secondary: {
    false: `${btnClassInitial} 
    bg-secondary active:bg-opacity-70 text-white`,
    true: `${btnClassInitial} ${outlineStyle}
    bg-light-blue active:bg-opacity-70 text-primary`,
  },
};

const btnSizes = {
  block: `w-full`,
  auto: `w-fit`,
};

const btnPadding = {
  lg: "px-[36px] py-[8px] text-base",
  sm: "px-3 py-2 text-[14px]",
};

const btnRounded = {
  md: "rounded",
  lg: "rounded-lg",
  xl: "rounded-xl",
  "3xl": "rounded-3xl",
};

const btnNames = ["primary", "muted", "secondary"];

const btnSizeNames = ["block", "auto"];

const btnOutlineOptions = ["true", "false"];

const btnPaddingNames = ["lg", "sm"];

const btnRoundedNames = ["md", "lg", "xl", "3xl"];

export {
  btnClass,
  btnNames,
  btnSizes,
  btnSizeNames,
  btnOutlineOptions,
  btnPadding,
  btnPaddingNames,
  btnRounded,
  btnRoundedNames,
};
