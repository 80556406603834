import React from "react";
import EditMaintenanceData from "../../../Features/Forms/Maintanance/Edit";

const Edit = () => {
  return (
    <div>
      <EditMaintenanceData />
    </div>
  );
};

export default Edit;
