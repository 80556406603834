import Input from "./Utils/Input";

import inputClass from "../../utils/input.style";

import useInput from "../hooks/useInput";

function TextInput(props) {
  const {
    field = "",
    input,
    setInput,
    mt,
    mb,
    validate,
    exValidate,
    disabled = false,
  } = props;

  const { handleChange } = useInput({
    input,
    setInput,
    field,
    validate,
    exValidate,
  });

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  return (
    <Input mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <input
        className={inputClass.base}
        value={input[field]?.value || ""}
        placeholder={input[field]?.placeholder || ""}
        onChange={handleChange}
        disabled={disabled}
        type="text"
      />
    </Input>
  );
}

export default TextInput;
