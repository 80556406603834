import SelectInput from "./SelectInput";


function MBScreenTabs(props) {
  const {
    list = [
      { id: 1, value: "Tab 1" },
      { id: 2, value: "Tab 2" },
      { id: 3, value: "Tab 3" },
    ],
    active = 1,
    setActive = () => {},
  } = props;

  return (
    <SelectInput active={active} setActive={setActive}>
      {list.map((item, i) => (
        <option key={i} value={item?.id || ""}>
          {item.value}
        </option>
      ))}
    </SelectInput>
  );
}

export default MBScreenTabs;


