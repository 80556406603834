import React from "react";
import Warehouses from '../../../Features/Tables/Warehouses'

const All = () => {
  return <div>
    <Warehouses />
    
  </div>;
};

export default All;
