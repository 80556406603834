import { TR, TDT, TA, TD } from "../../components";
import { FaCheckCircle, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import { RiDeleteBin5Line } from "react-icons/ri";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowCancelModal, setOrderId } = useContext(TableContext);

  function showCancel() {
    setOrderId(doc?.id);
    setShowCancelModal(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }

  return (
    <TR>
      <TDT
        name="PURCHASE ORDER ID"
        txt={
          doc?.purchase_order_id !== null
            ? `PO-${doc?.purchase_order_id}`
            : "--"
        }
      />
      <TDT name="ITEM NAME" txt={doc?.item_name || ""} />
      <TDT name="QUANTITY" txt={doc?.quantity || "--"} />
      <TDT name="TOTAL COST" txt={doc?.total_cost || "--"} />
      <TDT name="COST PER ITEM" txt={doc?.cost_per_item || "--"} />
      <TDT name="WAREHOUSE" txt={doc?.warehouses?.name || "--"} />

      <TDT
        name="ACTION"
        txt={
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`view/${doc?.id}`)}
              >
                <FaEye className="text-blue-500 text-2xl text-center" />
              </p>
            </div>
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
              </p>
            </div>
            <div
              onClick={
                hasOtherPermission(user, "delete_inventory")
                  ? showCancel
                  : handleAuth
              }
              className="cursor-pointer"
            >
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line className="text-red text-2xl text-center cursor-pointer" />
              </p>
            </div>
          </div>
        }
      />
    </TR>
  );
}

export default TableRow;
