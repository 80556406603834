import { useEffect } from "react";
import BasicModal from "./BasicModal";
import Lottie from "lottie-react";

import botAnimation from "../../Assets/animations/error.json";

import useDevice from "../../Hooks/useDevice";

function RequestErrorModal({ requestErrorModal }) {
  const {
    showRequestErrorModal = false,
    setShowRequestErrorModal = () => {},
    requestErrorMessage = "",
    requestErrorHeader = "Error !",
    requestErrorMessageTimeout = 30000,
  } = requestErrorModal;

  const device = useDevice();

  useEffect(() => {
    if (showRequestErrorModal === false) {
      return;
    }

    const timeOut = setTimeout(() => {
      setShowRequestErrorModal(false);
    }, requestErrorMessageTimeout);

    return () => {
      clearTimeout(timeOut);
    };
  }, [showRequestErrorModal]);

  return (
    <BasicModal
      bg="red"
      header={requestErrorHeader}
      showModal={showRequestErrorModal}
      setShowModal={setShowRequestErrorModal}
    >
      <div
        className=" p-2 w-full flex flex-wrap  items-center gap-x-4 "
        style={{ width: device === "sm" ? "auto" : "40em" }}
      >
        <div className=" p-2" style={{ width: "10em" }}>
          <Lottie animationData={botAnimation} />
        </div>
        <ErrorMessage requestErrorMessage={requestErrorMessage} />
      </div>
    </BasicModal>
  );
}

function ErrorMessage(props) {
  const { requestErrorMessage } = props;

  if (Array.isArray(requestErrorMessage)) {
    return (
      <div className=" flex flex-col gap-y-2">
        {requestErrorMessage.map((message, i) => {
          return <span key={i}>{message}</span>;
        })}
      </div>
    );
  }

  return (
    <div className="whitespace-normal w-fit" style={{}}>
      {requestErrorMessage}
    </div>
  );
}

export default RequestErrorModal;
