import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Polyline, Marker } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAxios from "../../../Hooks/useAxios";
import useMapLoader from "../../../Hooks/useMapLoader";

const HistoricalTrackViewer = () => {
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trackData, setTrackData] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: -1.2921, lng: 36.8219 });
  const request = useAxios();

  const mapKey = process.env.REACT_APP_MAP_KEY;

  const isLoaded = useMapLoader(mapKey);

  const mapRef = useRef(null);
  const fetchTrackData = async () => {
    const res = await request({
      method: "GET",
      url: "deviceapis/historical-records",
      params: {
        FGUID: "4d88bb50-3b01-4730-be87-fb40fda45f02",
        FType: parseInt(2),
        FAssetTypeID: 400,
        FStartTime: startDate.toISOString(),
        FEndTime: endDate.toISOString(),
      },
      show_loading: true,
    });

    if (res !== "error") {
      const coordinates = res.FObject.map((item) => ({
        lat: item.Lat,
        lng: item.Lon,
      }));

      setTrackData(coordinates);

      if (coordinates.length > 0) {
        setMapCenter(coordinates[0]);

        // Zoom the map to fit the polyline with animation
        if (mapRef.current) {
          const bounds = new window.google.maps.LatLngBounds();
          coordinates.forEach((coord) => bounds.extend(coord));

          // Zoom with smooth animation
          mapRef.current.fitBounds(bounds);
          mapRef.current.panToBounds(bounds);
        }
      }
    }

    setStartDate(new Date());
    setEndDate(new Date());
  };

  const handleSearch = () => {
    fetchTrackData();
  };

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  return (
    <div
      className="p-4"
      style={{ display: "flex", flexDirection: "column", gap: "16px" }}
    >
      <div className="flex flex-col gap-6 md:flex-row items-center bg-gray-100 p-6 rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Enter Vehicle Plate"
          value={vehiclePlate}
          onChange={(e) => setVehiclePlate(e.target.value)}
          className="w-full md:w-64 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex flex-col md:flex-row gap-4">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Start Date"
            className="w-full md:w-64 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="End Date"
            className="w-full md:w-64 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          onClick={handleSearch}
          className="px-6 py-3 bg-primary text-white font-semibold rounded-sm shadow-md"
        >
          Search
        </button>
      </div>

      <GoogleMap
        center={mapCenter}
        zoom={14}
        mapContainerStyle={{ width: "100%", height: "75vh" }}
        onLoad={(map) => (mapRef.current = map)}
      >
        {/* Polyline to represent historical track */}
        {trackData.length > 1 && (
          <Polyline
            path={trackData}
            options={{
              strokeColor: "#000000",
              strokeOpacity: 0.8,
              strokeWeight: 6,
            }}
          />
        )}

        {trackData.length > 0 && (
          <>
            <Marker position={trackData[0]} label="Start" />
            <Marker position={trackData[trackData.length - 1]} label="End" />
          </>
        )}
      </GoogleMap>
    </div>
  );
};

export default HistoricalTrackViewer;
