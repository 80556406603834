import useDevice from "../../Hooks/useDevice";

import LgScreenTabs from "./LgScreenTabs";

import MBScreenTabs from "./MBScreenTabs";

function TabBar(props) {
  const device = useDevice();

  if (device === "sm") {
    return <MBScreenTabs {...props} />;
  }

  return <LgScreenTabs {...props} />;
}

export default TabBar;
