import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Name" field="name" setOrder={setOrder} />
      <THTS txt="Location" field="description" setOrder={setOrder} />
      <THTS txt="Description" field="location" setOrder={setOrder} />
       
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
