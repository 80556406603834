import { TR, TDT, TA, TD } from "../../components";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowDeleteModal, setSystemId, systemId } =
    useContext(TableContext);

  function showDelete() {
    setSystemId(doc?.id);
    setShowDeleteModal(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }

  return (
    <TR>
      <TDT name="Name" txt={doc?.name || "--"} />
      <TDT name="Email" txt={doc?.email || ""} />
      <TDT name="Phone" txt={doc?.phone || "--"} />
      <TDT name="Role" txt={doc?.roles?.name || "--"} />
      <TDT
        name="Status"
        txt={
          <div
            className={`rounded-lg flex items-center justify-between px-3 w-fit ${
              doc.is_active ? "bg-light_green" : "bg-light_orange"
            }`}
          >
            <p
              className={`${
                doc.is_active ? "text-dark_green" : "text-dark_orange"
              } font-semibold text-sm`}
            >
              {doc?.is_active ? "Active" : "Inactive" || ""}
            </p>
          </div>
        }
      />
      <TDT
        name="ACTION"
        txt={
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`view/${doc?.id}`)}
              >
                <FaEye className="text-blue-500 text-2xl text-center" />
              </p>
            </div>
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
              </p>
            </div>
            <div
              onClick={
                hasOtherPermission(user, "delete_member")
                  ? showDelete
                  : handleAuth
              }
              className="cursor-pointer"
            >
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line className="text-red text-2xl text-center cursor-pointer" />
              </p>
            </div>
          </div>
        }
      />
    </TR>
  );
}

export default TableRow;
