import React from "react";
import AddOtherExpenses from "../../../Features/Forms/OtherExpenses/Add";

const Add = () => {
  return (
    <div>
      <AddOtherExpenses />
    </div>
  );
};

export default Add;
