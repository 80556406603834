const init_member = {
  name: {
    value: "",
    label: "Name",
    placeholder: "Enter Member Name",
  },
  email: {
    value: "",
    label: "Email",
    placeholder: "Enter Email",
  },
  phone: {
    value: "",
    label: "Phone",
    placeholder: "Enter Phone Number",
  },
  role_id: {
    value: "",
    label: "Role",
    placeholder: "Select Role",
  },
};

export default init_member;
