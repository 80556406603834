import React from "react";
import Layout from "../../Components/Layout";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return <Layout SideBar={Sidebar} Section={Outlet} />;
};

export default Dashboard;
