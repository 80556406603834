import React from "react";
import AddMember from '../../../Features/Forms/Members/Add'

const Add = () => {
  return <div>
    <AddMember />
  </div>;
};

export default Add;
