import React from "react";
import BasicModal from "../../../../Components/Modals/BasicModal";
import Btn from "../../../../Components/Buttons/Btn";
import { RiDeleteBin5Line } from "react-icons/ri";

const ApproveModal = ({
  showApproveModal,
  setShowApproveModal,
  handleApprove,
}) => {
  if (showApproveModal) {
    return (
      <BasicModal
        showModal={showApproveModal}
        setShowModal={setShowApproveModal}
        header="Approve Request"
      >
        <div className="m-auto p-5">
          <h2 className="text-center text-red text-xl font-bold  mb-6">
            Are you sure you want to approve this request ?
          </h2>
          <div className="flex justify-between items-center">
            <Btn
              outline={true}
              btn="border"
              onClick={() => setShowApproveModal(false)}
            >
              <p className="">Cancel</p>
            </Btn>
            <Btn outline={true} btn="border" onClick={handleApprove}>
              <div className="flex justify-between items-center gap-x-2">
                <RiDeleteBin5Line className="text-red" />
                <p className="">Confirm</p>
              </div>
            </Btn>
          </div>
        </div>
      </BasicModal>
    );
  }
};

export default ApproveModal;
