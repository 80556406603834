import React, { useEffect, useState } from "react";
import BasicModal from "../../../../Components/Modals/BasicModal";
import Btn from "../../../../Components/Buttons/Btn";
import useAxios from "../../../../Hooks/useAxios";
import { RiCheckLine } from "react-icons/ri"; 
import SearchableSelect from "../../../Forms/Components/Inputs/SearchableSelect";

const AssignDeviceModal = ({ showAssignModal, setShowAssignModal, handleAssignDevice }) => {
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const request = useAxios();

  const [input, setInput] = useState({
    device_id: {
      value: "",
      label: "Search a device to assign",
      placeholder: "Type here to search a device",
    },
  });

  useEffect(() => {
    if (showAssignModal) {
      fetchDevices();
    }
  }, [showAssignModal]);

  const fetchDevices = async () => {
    setIsLoading(true);
    try {
      const res = await request({
        method: "GET",
        url: "devices/all", 
        params: { page: 1, limit: 10, order: "asc" },
        show_loading: false,
      });
      setDevices(res?.data || []); 
    } catch (error) {
      console.error("Error fetching devices:", error);
      setDevices([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicModal showModal={showAssignModal} setShowModal={setShowAssignModal} header="Assign Device">
      <div className="m-auto p-5">
        
        {/* Searchable device select input */}
        <div className="mb-5">
          <SearchableSelect
            input={input}
            setInput={setInput}
            url="devices/search"
            setOptions={setDevices}
            field="device_id"
          >
            {!isLoading && devices.length > 0 ? (
              devices.map((device) => (
                <option key={device.id} value={device.id}>
                  {device.name}
                </option>
              ))
            ) : (
              <option value="">No devices available</option>
            )}
          </SearchableSelect>
        </div>

        {/* Action buttons */}
        <div className="flex justify-between items-center p-5 gap-6">
          <Btn outline={true} btn="border" onClick={() => setShowAssignModal(false)}>
            <p>Cancel</p>
          </Btn>
          <Btn
            outline={true}
            btn="border"
            onClick={() => handleAssignDevice(document.getElementById("device-select").value)}
          >
            <div className="flex items-center gap-x-2">
              <RiCheckLine className="text-primary" />
              <p>Assign</p>
            </div>
          </Btn>
        </div>
      </div>
    </BasicModal>
  );
};

export default AssignDeviceModal;
