import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs"; 
import SearchableSelect from '../../Components/Inputs/SearchableSelect'
import initDoc from "../initDoc";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";

const Add = () => {
  const [input, setInput] = useState(initDoc);
  const navigate = useNavigate();
  const request = useAxios();

  const [vehicles, setVehicles] = useState({});
  const [members, setMembers] = useState({});



  useEffect(() => {
    getMaintenances();
  }, []);

  async function getMaintenances() {
    let res = await request({
      method: "get",
      url: "vehicles/maintenance/all-records",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
    }
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "vehicles/maintenance/add",
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/vehicles/maintenance");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Maintenance</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
        <SearchableSelect
            input={input}
            setInput={setInput}
            field="vehicle_id"
            validate={validate}
            url="vehicles/search-vehicle"
            setOptions={setVehicles}
          >
            {vehicles?.docs?.map((vehicle, i) => (
              <option value={vehicle?.id} key={i} label={vehicle.plate_no}>
                {vehicle?.plate_no}
              </option>
            ))}
          </SearchableSelect>
          <TextInput
            input={input}
            setInput={setInput}
            field="name"  
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="last_maintenance_date"  
            validate={validate}
          />
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="requested_by"
            validate={validate}
            url="members/search"
            setOptions={setMembers}
          >
            {members?.docs?.map((member, i) => (
              <option value={member?.id} key={i} label={member.name}>
                {member?.name}
              </option>
            ))}
          </SearchableSelect>
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="maintenance_cost"  
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="performed_by"  
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="actual_maintenance_date"  
            validate={validate}
          />
          <DateInput
            input={input}
            setInput={setInput}
            field="next_maintenance_date"  
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="description"  
            validate={validate}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/vehicles/maintenance")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
