const init_records = {
  fuel_date: {
    value: "",
    label: "Fuel Date",
    placeholder: "Pick Date",
  },
  vehicle_id: {
    value: "",
    label: "Vehicle",
    placeholder: "Search Vehicle",
  },
  fueled_by: {
    value: "",
    label: "Fueled By",
    placeholder: "Person's Name",
  },
  trip_id: {
    value: "",
    label: "Trip",
    placeholder: "Enter Trip ID",
  },
  fuel_amount: {
    value: "",
    label: "Fuel Amount (Liters)",
    placeholder: "Enter Amount in Liters",
  },
  price_per_litre: {
    value: "",
    label: "Price Per Litre",
    placeholder: "Enter Price",
  },
  cost: {
    value: "",
    label: "Total Cost",
    placeholder: "Enter Total Cost",
  },
  filling_station: {
    value: "",
    label: "Filling Station",
    placeholder: "Enter Station Name",
  },
  nano_receipt: {
    value: "",
    label: "Receipt",
    placeholder: "upload receipt",
  },
};

export default init_records;

