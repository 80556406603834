import React from "react";
import NavBtn from "../NavBtn";
import { FaHistory } from "react-icons/fa";

const UserLogs = (props) => {
  const {
    exactPath = "/dashboard/user-logs",
    to = "/dashboard/user-logs",
    partialPath = "/user-logs",
    label = "User Logs",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaHistory}
    />
  );
};

export default UserLogs;
