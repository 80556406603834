import { useJsApiLoader } from "@react-google-maps/api";

const useMapLoader = (googleMapsApiKey) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  return isLoaded;
};

export default useMapLoader;
