import React from "react";

const bgs = {
  primary: "#103059",
  secondary: "#174785",
  red: "#DB4437",
  white: "#FFFFFF",
};

function BasicModal(props) {
  const {
    children,
    showModal = false,
    setShowModal = () => {},
    bg = "secondary",
    header = "Title",
    showHeader = true,
  } = props;

  if (!showModal) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex justify-center items-center mx-2">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div
            className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-xs"
            style={{
              backgroundColor: "rgba(249, 250, 254, 1)",
            }}
          >
            {showHeader && (
              <div
                className="w-full flex justify-between items-center px-4 py-2"
                // style={{
                //   backgroundColor: bgs[bg] || bgs["primary"],
                // }}
              >
                <span className="text-2xl font-medium">{header}</span>
                <span
                  onClick={() => setShowModal(false)}
                  className="text-md bg-red px-3 py-1 -mt-4 -mr-4 hover:font-extrabold hover:text-black cursor-pointer text-white"
                >
                  X
                </span>
              </div>
            )}
            <div className="relative px-6 py-4 flex-auto w-full">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}

export default BasicModal;
