import { useState } from "react";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";

function Limit(props) {
  const { pagination = {} } = props;
  const { limit = 10, setLimit = () => {} } = pagination;
  const [show, setShow] = useState(false);

  return (
    <div className="flex items-center gap-x-4 w-full md:w-auto">
      <span className="text-gray-600">Rows per page</span>
      <div className="relative">
        <button
          onClick={() => setShow((c) => !c)}
          className="flex items-center justify-between p-2 bg-gray-200 hover:bg-gray-300 rounded-md shadow"
        >
          <span className="text-gray-800">{limit}</span>
          <span className="text-gray-600">
            {show ? <MdArrowDropUp /> : <MdArrowDropDown />}
          </span>
        </button>
        {show && <DropDown setLimit={setLimit} setShow={setShow} />}
      </div>
    </div>
  );
}

function DropDown(props) {
  const { setLimit = () => {}, setShow } = props;
  const allowed = [10, 15, 20, 25];

  const handleSelect = (no) => {
    setLimit(no);
    setShow(false);
  };

  return (
    <ul className="absolute bg-white border rounded-md shadow-lg w-full py-1 px-2 z-50">
      {allowed.map((no, i) => (
        <li
          key={i}
          onClick={() => handleSelect(no)}
          className="cursor-pointer py-1 px-2 hover:bg-gray-100 rounded"
        >
          {no}
        </li>
      ))}
    </ul>
  );
}

export default Limit;
