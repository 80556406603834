// input={"name":{value:"",label:""}}

import InputAlt from "./Utils/InputAlt";

import inputClass from "../../utils/input.style";

import useInput from "../hooks/useInput";

function TextArea(props) {
  const { field = "", input, setInput, mt, mb, validate, rows } = props;

  const { handleChange } = useInput({ input, setInput, field, validate });

  if (typeof input !== "object") {
    return null;
  }

  return (
    <InputAlt mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <textarea
        className={inputClass.base}
        value={input[field]?.value || ""}
        placeholder={input[field]?.placeholder || ""}
        onChange={handleChange}
        disabled={input[field]?.disabled || false}
        rows={rows || 4}
      />
    </InputAlt>
  );
}

export default TextArea;
