import { useContext } from "react";
import { Thead, THT, THTS } from "../../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Driver" field="driver" setOrder={setOrder} />
      <THTS txt="Vehicle Reg" field="reg_number" setOrder={setOrder} />
      <THTS txt="Fuel Type" field="fuel_type" setOrder={setOrder} />
      <THTS txt="Fuel Quantity" field="quantity" setOrder={setOrder} />
      <THTS txt="Total Fuel Cost" field="cost" setOrder={setOrder} />
      <THTS txt="Fuel Station" field="station" setOrder={setOrder} />
      <THTS txt="Fuel Efficiency" field="efficiency" setOrder={setOrder} />
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
