import { TR, TDT, TA, TD } from "../../components";
import { RiAddCircleLine } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import { convDate3, convDateTime4 } from "../../../../Util/Time";
import Badge from "../../../../Components/Badge";
import {
  hasFormPermission,
  hasOtherPermission,
} from "../../../../Util/Permissions";
import useApp from "../../../../Hooks/useApp";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowCancelModal, setShowAssignModal, setVehicleId } =
    useContext(TableContext);

  const handleAssign = () => {
    setVehicleId(doc.id);
    setShowAssignModal(true);
  };

  const handleCancel = () => {
    setVehicleId(doc?.id);
    setShowCancelModal(true);
  };

  const handleAuth = () => {
    setShowNotAuthorized(true);
  };

  return (
    <TR>
      <TDT name="Vehicle" txt={doc?.vehicles?.plate_no || "--"} />
      <TDT name="Route" txt={doc?.routes?.name || "--"} />
      <TDT name="Departure Point" txt={doc?.departure_point || "--"} />
      <TDT name="Destination" txt={doc?.destination || "--"} />
      <TDT
        name="Departure Date"
        txt={convDateTime4(doc?.departure_date) || "--"}
      />
      <TDT
        name="Actual Arrival Date"
        txt={convDateTime4(doc?.actual_arrival_date) || "--"}
      />
      <TDT
        name="Expected Arrival Date"
        txt={convDate3(doc?.expected_arrival_date) || "--"}
      />
      <TDT name="Scheduled By" txt={doc?.members?.name || "--"} />
      <TDT
        name="Status"
        txt={
          <Badge
            txt={doc?.trip_status?.name || "--"}
            bg={
              doc?.trip_status?.name === "pending"
                ? "secondary"
                : doc?.trip_status?.name === "assigned"
                ? "light_green"
                : doc?.trip_status?.name === "in_progress"
                ? "custom-blue"
                : doc?.trip_status?.name === "complete"
                ? "green"
                : "red"
            }
          />
        }
      />
      <TA name="ACTIONS" id={doc?.id}>
        <div className="flex flex-col gap-4">
          {doc?.trip_status?.name !== "assigned" &&
            doc?.trip_status?.name !== "cancelled" &&
            !doc?.vehicle_id && (
              <div
                className="cursor-pointer flex"
                onClick={
                  hasFormPermission(user, "assign_trip")
                    ? handleAssign
                    : handleAuth
                }
              >
                <p className="  text-sm flex gap-2">
                  <RiAddCircleLine className="text-green-500 text-xl" />
                  <span>Assign</span>
                </p>
              </div>
            )}
          {doc?.trip_status?.name !== "cancelled" && (
            <div
              className="cursor-pointer flex"
              onClick={
                hasOtherPermission(user, "cancel_dispatch")
                  ? handleCancel
                  : handleAuth
              }
            >
              <p className="  text-sm flex gap-2">
                <MdCancel className="text-xl  text-red" />
                <span>Cancel</span>
              </p>
            </div>
          )}
        </div>
      </TA>
    </TR>
  );
}

export default TableRow;
