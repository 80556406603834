function InputError(props) {
  const { input } = props;

  if (!input?.error) {
    return <div className=" text-xs  invisible">* Error message</div>;
  }

  return (
    <div className=" text-red text-xs ">
      {input?.e_message ? input?.e_message : "* Input error"}
    </div>
  );
}

export default InputError;
