import React from "react";
import EditFleet from "../../../Features/Forms/FleetRoutes/Edit";

const Edit = () => {
  return (
    <div>
      <EditFleet />
    </div>
  );
};

export default Edit;
