import useApp from "../../Hooks/useApp";
import { MdOutlineClose } from "react-icons/md";
import Logo from "../Sidebar/Logo";

function Aside(props) {
  const { device, setSideBarOpen, sideBarOpen } = useApp();
  const { children } = props;

  return (
    <div
      className={`fixed top-0 left-0 h-full flex flex-col gap-y-8 shadow-md w-fit px-2 py-4 bg-primary transition-transform duration-300 ${
        sideBarOpen ? "translate-x-0 z-50" : "-translate-x-full"
      } ${device === "sm" ? "absolute" : ""}`}
    >
      <div className="flex justify-between">
        <Logo />
        {device === "sm" && (
          <MdOutlineClose
            className="cursor-pointer text-3xl text-white"
            onClick={() => setSideBarOpen(false)}
          />
        )}
      </div>
      <div className="flex-1 flex flex-col overflow-y-auto gap-y-6">
        {children}
      </div>
    </div>
  );
}

export default Aside;
