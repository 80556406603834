import avatar from "../../Assets/svgs/top-avatar.svg";

function Avatar() {
  return (
    <div className="flex items-center gap-4">
      <img src={avatar} alt="" className="rounded-full" />
    </div>
  );
}

export default Avatar;
