import TableHead from "./Table/TableHead";

import useTable from "./Provider/useTable";
import TableRow from "./Table/TableRow";

import { Table, TBody } from "../components";

import { useNavigate } from "react-router-dom";
import TableContext from "./Provider/TableContext";
import DeleteModal from "./Table/DeleteModal";
import ApproveModal from "./Table/ApproveModal";

function OtherExpenses() {
  const {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination,
    genders,
    dobRange,
    setDobRange,
    selectedGenders,
    setSelectedGenders,
    doaRange,
    setDoaRange,
    setOrder,
    clearFilters,
    expenseId,
    setExpenseId,
    refetchDocs,
    showDeleteModal,
    setShowDeleteModal,
    handleDelete,
    showApprove,
    setShowApprove,
    handleApprove,
  } = useTable();

  const navigate = useNavigate();

  return (
    <>
      <TableContext.Provider
        value={{
          limit,
          setLimit,
          docs,
          setDocs,
          isLoading,
          setIsLoading,
          isFiltered,
          setIsFiltered,
          pagination,
          genders,
          dobRange,
          setDobRange,
          selectedGenders,
          setSelectedGenders,
          doaRange,
          setDoaRange,
          setOrder,
          clearFilters,
          expenseId,
          setExpenseId,
          refetchDocs,
          showDeleteModal,
          setShowDeleteModal,
          handleDelete,
          showApprove,
          setShowApprove,
          handleApprove,
        }}
      >
        <Table
          label="All Roles"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setDocs={setDocs}
          isFiltered={isFiltered}
          handleAdd={() => navigate("add")}
          setIsFiltered={setIsFiltered}
          handleRemoveFilters={clearFilters}
          pagination={pagination}
          url="other-expenses/search"
        >
          <TableHead setOrder={setOrder} />
          <TBody>
            {docs.map((doc, i) => {
              return <TableRow key={doc?.id || i} doc={doc} />;
            })}
          </TBody>
        </Table>
      </TableContext.Provider>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          handleDelete={handleDelete}
        />
      )}
      {showApprove && (
        <ApproveModal
          showApprove={showApprove}
          setShowApprove={setShowApprove}
          handleApprove={handleApprove}
        />
      )}
    </>
  );
}

export default OtherExpenses;
