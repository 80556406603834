import axios from "axios";
import useHandleError from "./useHandleError";
import { getDecryptedData } from "../Util/store";
import { useContext } from "react";
import AppContext from "../Provider/AppContext";

const baseUrl = "https://www.fleetapi.exhibitsolutions.co.ke";

function useAxios() {
  const handleError = useHandleError();
  const { loadingModal, requestSuccessModal } = useContext(AppContext);

  const token = getDecryptedData("token");

  const request = async (obj = {}) => {
    const {
      method = "GET",
      url = "",
      headers = {},
      body = {},
      params = {},
      custom_token = null,
      show_error = true,
      show_loading = true,
      loadingFunc = null,
      send_token = true,
      responseType = "json",
      prolongTimeout = 500,
      show_success = true,
    } = obj;

    try {
      if (show_loading) {
        loadingModal.loading();
      }
      if (typeof loadingFunc === "function") {
        loadingFunc(true);
      }

      if (!method || !url) {
        throw { custom: true, message: "Method and url required" };
      }


      const res = await axios({
        headers: {
          Authorization: `Bearer ${custom_token || token}`,
          ...headers,
        },
        method,
        url: `${baseUrl}/${url}`,
        data: body,
        params,
        responseType,
      });

      if (show_success && res?.data?.server_message) {
        requestSuccessModal.handleShow({
          header: "Success",
          message: res.data.server_message,
        });
      }
      return res.data;
    } catch (e) {
      if (show_error) {
        handleError(e);
      }
      return "error";
    } finally {
      if (typeof loadingFunc === "function") {
        if (prolongTimeout) {
          setTimeout(() => {
            loadingFunc(false);
          }, prolongTimeout);
        } else {
          loadingFunc(false);
        }
      }
      if (show_loading) {
        if (prolongTimeout) {
          setTimeout(() => {
            loadingModal.hide();
          }, prolongTimeout);
        } else {
          loadingModal.hide();
        }
      }
    }
  };

  return request;
}

export default useAxios;
