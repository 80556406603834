import React from "react";
import EditFuelRecords from "../../../Features/Forms/FuelManagement/Edit";

const Edit = () => {
  return (
    <div>
      <EditFuelRecords />
    </div>
  );
};

export default Edit;
