import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import init_routes from "../init_routes";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";

const Add = () => {
  const [input, setInput] = useState(init_routes);

  const navigate = useNavigate();

  const request = useAxios();



  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "routes/add",
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/routes");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Route</h3>
      <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-6">
        <TextInput input={input} setInput={setInput} field="name" validate={validate} />
        <TextInput input={input} setInput={setInput} field="no_of_stops" validate={validate} />
        <TextInput input={input} setInput={setInput} field="description" validate={validate} />
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/routes")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
