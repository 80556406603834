import React from 'react';


const DropDown = ({ items, onSelect, activePath }) => {
  return (
    <div className= "w-full mt-2">
      <ul className="list-none p-0 m-0 space-y-1 rounded-md">
        {items.map((item, index) => (
          <li key={index} className="flex justify-center">
            <button 
              onClick={() => onSelect(item)}
              className={`pl-14 py-2 text-neutral-white  rounded-md text-left w-full hover:bg-white hover:bg-opacity-20 ${activePath === item.to?'bg-[#FFFFFF33]': ''}`}
             
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;

