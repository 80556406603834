import React from "react";
import EditLogistic from "../../../Features/Forms/Logistics/Edit";

const Edit = () => {
  return (
    <div>
      <EditLogistic />
    </div>
  );
};

export default Edit;
