import React from "react";
import NavBtn from "../NavBtn";
import { HiOutlineUserGroup } from "react-icons/hi2";

const Members = (props) => {
  const {
    exactPath = "/dashboard/members",
    to = "/dashboard/members",
    partialPath = "/members",
    label = "Members",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={HiOutlineUserGroup}
    />
  );
};

export default Members;
