import { TR, TDT, TA, TD } from "../../components";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";
import { MdShare } from "react-icons/md";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowDeleteModal, setExpenseId, setShowApprove } =
    useContext(TableContext);

  function showDelete() {
    setExpenseId(doc?.id);
    setShowDeleteModal(true);
  }

  function showApproveModal() {
    setExpenseId(doc?.id);
    setShowApprove(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }

  return (
    <TR>
      <TDT name="EXPENSE NAME" txt={doc?.name || ""} />
      <TDT name="DESCRIPTION" txt={doc?.description || ""} />
      <TDT
        name="STATUS"
        txt={
          <div
            className={`w-fit rounded-2xl px-2 text-white text-sm ${
              doc?.expense_status == "pending"
                ? "bg-red"
                : doc?.expense_status == "cancelled"
                ? "bg-yellow"
                : "bg-green"
            }`}
          >
            {doc?.expense_status || ""}
          </div>
        }
      />
      <TDT
        name="APPROVED BY"
        txt={
          doc?.members_other_expenses_approved_byTomembers?.name ||
          "Not yet Approved"
        }
      />
      <TA id={doc?.id}>
        <div className="flex flex-col gap-4">
          {doc?.expense_status === "pending" && (
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
                <span>Edit</span>
              </p>
            </div>
          )}
          {doc?.expense_status === "pending" && (
            <div className="cursor-pointer">
              <p className="text-center flex gap-2" onClick={showApproveModal}>
                <MdShare className="text-2xl text-center text-green" />
                <span>Approve</span>
              </p>
            </div>
          )}
          {doc?.expense_status === "pending" && (
            <div
              onClick={
                hasOtherPermission(user, "delete_role")
                  ? showDelete
                  : handleAuth
              }
              className="cursor-pointer"
            >
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line className="text-red text-2xl text-center cursor-pointer" />
                <span>Delete</span>
              </p>
            </div>
          )}
        </div>
      </TA>
    </TR>
  );
}

export default TableRow;
