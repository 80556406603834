import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Summary from "../Screens/Summary";
import Dashboard from "../Screens/Dashboard";
import SignIn from "../Screens/SignIn";

// Members
import Members from "../Screens/Members";
import AllMembers from "../Screens/Members/All";
import AddMember from "../Screens/Members/Add";
import EditMember from "../Screens/Members/Edit";
import ViewMember from "../Screens/Members/View";

//Vehicles
import Vehicles from "../Screens/Vehicles";
import AllVehicles from "../Screens/Vehicles/All";
import AddVehicle from "../Screens/Vehicles/Add";
import EditVehicle from "../Screens/Vehicles/Edit";
import ViewVehicle from "../Screens/Vehicles/View";

//vehicles logistics
import Logistics from "../Screens/Logistics";
import AllLogistics from "../Screens/Logistics/All";
import AddLogistic from "../Screens/Logistics/Add";
import EditLogistic from "../Screens/Logistics/Edit";
import ViewLogistic from "../Screens/Logistics/View";

//vehicle maintenance
import Maintenance from "../Screens/Maintanance";
import AllMaintenanceData from "../Screens/Maintanance/All";
import AddMaintenanceData from "../Screens/Maintanance/Add";
import EditMaintenanceData from "../Screens/Maintanance/Edit";
import ViewMaintenanceData from "../Screens/Maintanance/View";

//Routes
import FleetRoutes from "../Screens/FleetRoutes";
import AllFleetRoutes from "../Screens/FleetRoutes/All";
import AddRoute from "../Screens/FleetRoutes/Add";
import EditRoute from "../Screens/FleetRoutes/Edit";
import ViewRoute from "../Screens/FleetRoutes/View";

//Devices
import Devices from "../Screens/Devices";
import AllDevices from "../Screens/Devices/All";
import AddDevice from "../Screens/Devices/Add";
import EditDevice from "../Screens/Devices/Edit";
import ViewDevice from "../Screens/Devices/View";
import AddVehicleDevice from "../Screens/Devices/View/AddVehicles";

//Warehouses
import Warehouses from "../Screens/Warehouses";
import AllWarehouses from "../Screens/Warehouses/All";
import AddWarehouses from "../Screens/Warehouses/Add";
import EditWarehouses from "../Screens/Warehouses/Edit";

//Fuel Records
import FuelRecords from "../Screens/FuelManagement";
import AllFuelRecords from "../Screens/FuelManagement/All";
import AddFuelRecord from "../Screens/FuelManagement/Add";
import EditFuelRecord from "../Screens/FuelManagement/Edit";
import ViewFuelRecord from "../Screens/FuelManagement/View";

// Roles
import Roles from "../Screens/Roles";
import AllRoles from "../Screens/Roles/All";
import AddRole from "../Screens/Roles/Add";
import EditRole from "../Screens/Roles/Edit";

// Requests
import Requests from "../Screens/Requests";
import AllRequests from "../Screens/Requests/All";
import AddRequest from "../Screens/Requests/Add";
import EditRequest from "../Screens/Requests/Edit";

// Purchase Orders
import PurchaseOrders from "../Screens/PurchaseOrders";
import AllOrders from "../Screens/PurchaseOrders/All";
import AddOrder from "../Screens/PurchaseOrders/Add";
import EditOrder from "../Screens/PurchaseOrders/Edit";

// Inventory
import Inventory from "../Screens/Inventory";
import AllInventory from "../Screens/Inventory/All";
import AddInventory from "../Screens/Inventory/Add";
import EditInventory from "../Screens/Inventory/Edit";
import ViewInventory from "../Screens/Inventory/View";

// Other Expenses
import OtherExpenses from "../Screens/OtherExpensenses";
import AllOtherExpenses from "../Screens/OtherExpensenses/All";
import AddOtherExpenses from "../Screens/OtherExpensenses/Add";
import EditOtherExpenses from "../Screens/OtherExpensenses/Edit";

//Reports//
import Reports from "../Screens/Reports";
//Vehicle reports
import VehicleReports from "../Screens/Reports/VehicleReports";
import AllVehicleReports from "../Screens/Reports/VehicleReports/All";

//Fuel reports
import FuelReport from "../Screens/Reports/FuelReport";
import AllFuelReports from "../Screens/Reports/FuelReport/All";
import AbnormalTrajectory from "../Screens/Reports/AbnormalTrajectory";

//Trip reports
import TripReport from "../Screens/Reports/TripReport";
import AllTripReports from "../Screens/Reports/TripReport/All";
import Protected from "../Protected";

// Finance
import Finance from "../Screens/Finance";
import AllFinance from "../Screens/Finance/All";

// Monitoring
import Monitoring from "../Screens/Monitoring";
import AllMonitoring from "../Screens/Monitoring/All";

//viewtracks
import ViewTracks from "../Screens/ViewTracks";
import AllViewTracks from "../Screens/ViewTracks/All";

//TrackAssets
import ViewAssets from "../Screens/ViewAssets";
import AllViewAssets from "../Screens/ViewAssets/All";

//Company
import Company from "../Screens/Company";
import AllCompany from "../Screens/Company/components";

//userLogs
import UserLogs from "../Screens/UserLogs";
import AllUserLogs from "../Screens/UserLogs/All";
import { hasScreenPermission, hasFormPermission } from "../Util/Permissions";
import useApp from "../Hooks/useApp";
import NotAuthorized from "../NotAuthorized";

const AllRoutes = () => {
  const { user } = useApp();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<SignIn />} />

        <Route element={<Protected />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route
              path=""
              element={
                !hasScreenPermission(user, "dashboard") ? (
                  <NotAuthorized />
                ) : (
                  <Summary />
                )
              }
            />
            <Route path="members" element={<Members />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "all_members") ? (
                    <AllMembers />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "member_add") ? (
                    <AddMember />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "member_edit") ? (
                    <EditMember />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="view/:id"
                element={
                  hasScreenPermission(user, "member_single") ? (
                    <ViewMember />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>

            <Route path="company-details" element={<Company />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "company_details") ? (
                    <AllCompany />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>

            <Route path="user-logs" element={<UserLogs />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "member_logs") ? (
                    <AllUserLogs />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>

            <Route path="vehicles" element={<Vehicles />}>
              <Route path="fleets" element={<Vehicles />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "vehicles") ? (
                      <AllVehicles />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="add"
                  element={
                    hasFormPermission(user, "vehicle_add") ? (
                      <AddVehicle />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    hasFormPermission(user, "vehicle_edit") ? (
                      <EditVehicle />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="view/:id"
                  element={
                    hasScreenPermission(user, "single_vehicle") ? (
                      <ViewVehicle />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="dispatch" element={<Logistics />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "dispatch_scheduling") ? (
                      <AllLogistics />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="add"
                  element={
                    hasFormPermission(user, "vehicle_add_dispatch") ? (
                      <AddLogistic />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    hasFormPermission(user, "vehicle_edit_dispatch") ? (
                      <EditLogistic />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="view/:id"
                  element={
                    hasScreenPermission(user, "single_dispatch_scheduling") ? (
                      ViewLogistic
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="maintenance" element={<Maintenance />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "vehicle_maintenance") ? (
                      <AllMaintenanceData />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="add"
                  element={
                    hasFormPermission(user, "add_maintainance_record") ? (
                      <AddMaintenanceData />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    hasFormPermission(user, "edit_maintainance_record") ? (
                      <EditMaintenanceData />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
                <Route
                  path="view/:id"
                  element={
                    hasScreenPermission(user, "single_vehicle_maintenance") ? (
                      <ViewMaintenanceData />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
            </Route>

            <Route path="finance" element={<Finance />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "finance") ? (
                    <AllFinance />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>

            <Route path="monitoring" element={<Monitoring />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "device_monitoring") ? (
                    <AllMonitoring />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="monitoring/view-tracks" element={<ViewTracks />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "view_tracks") ? (
                    <AllViewTracks />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="monitoring/track-assets" element={<ViewAssets />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "tracks_assets") ? (
                    <AllViewAssets />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>

            <Route path="routes" element={<FleetRoutes />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "routes") ? (
                    <AllFleetRoutes />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "add_route") ? (
                    <AddRoute />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "edit_route") ? (
                    <EditRoute />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="view/:id"
                element={
                  hasScreenPermission(user, "single_route") ? (
                    <ViewRoute />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="fuel" element={<FuelRecords />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "fuel_records") ? (
                    <AllFuelRecords />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "add_fuel") ? (
                    <AddFuelRecord />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "edit_fuel") ? (
                    <EditFuelRecord />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="view/:id"
                element={
                  hasScreenPermission(user, "single_fuel_record") ? (
                    <ViewFuelRecord />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="devices" element={<Devices />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "devices") ? (
                    <AllDevices />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "add_device") ? (
                    <AddDevice />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "edit_device") ? (
                    <EditDevice />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="view/:id"
                element={
                  hasScreenPermission(user, "single_device") ? (
                    <ViewDevice />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="warehouses" element={<Warehouses />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "warehouses") ? (
                    <AllWarehouses />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "warehouse_add") ? (
                    <AddWarehouses />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "warehouse_edit") ? (
                    <EditWarehouses />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="roles" element={<Roles />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "roles") ? (
                    <AllRoles />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "can_add_role") ? (
                    <AddRole />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "can_edit_role") ? (
                    <EditRole />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="requests" element={<Requests />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "requests") ? (
                    <AllRequests />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "add_request") ? (
                    <AddRequest />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "edit_request") ? (
                    <EditRequest />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
            </Route>
            <Route path="purchase-orders" element={<PurchaseOrders />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "purchase_orders") ? (
                    <AllOrders />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "add_purchase_order") ? (
                    <AddOrder />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route path="edit/:id" element={<EditOrder />} />
            </Route>
            <Route path="inventory" element={<Inventory />}>
              <Route
                path=""
                element={
                  hasScreenPermission(user, "inventory") ? (
                    <AllInventory />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="add"
                element={
                  hasFormPermission(user, "inventory_add") ? (
                    <AddInventory />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route
                path="edit/:id"
                element={
                  hasFormPermission(user, "inventory_edit") ? (
                    <EditInventory />
                  ) : (
                    <NotAuthorized />
                  )
                }
              />
              <Route path="view/:id" element={<ViewInventory />} />
            </Route>

            <Route path="expenses" element={<OtherExpenses />}>
              <Route path="" element={<AllOtherExpenses />} />
              <Route path="add" element={<AddOtherExpenses />} />
              <Route path="edit/:id" element={<EditOtherExpenses />} />
            </Route>

            <Route path="reports" element={<Reports />}>
              <Route path="vehicle" element={<VehicleReports />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "vehicle_reports") ? (
                      <AllVehicleReports />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="fuel" element={<FuelReport />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "fuel_reports") ? (
                      <AllFuelReports />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="abnormal-trajectory" element={<FuelReport />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(
                      user,
                      "abnormal_fuel_trajectory_report"
                    ) ? (
                      <AbnormalTrajectory />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="fuel-analysis" element={<FuelReport />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "fuel_consumption_report") ? (
                      <AllFuelReports />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
              <Route path="trip" element={<TripReport />}>
                <Route
                  path=""
                  element={
                    hasScreenPermission(user, "trip_reports") ? (
                      <AllTripReports />
                    ) : (
                      <NotAuthorized />
                    )
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
