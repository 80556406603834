import React, { useState } from "react";
import EmailInput from "../../Features/Forms/Components/Inputs/EmailInput";
import PasswordInput from "../../Features/Forms/Components/Inputs/PasswordInput";
import Checkbox from "../../Features/Forms/Components/Inputs/CheckBoxInput";
import Btn from "../../Components/Buttons/Btn";
import useAxios from "../../Hooks/useAxios";
import useForm from "../../Features/Forms/Components/hooks/useForm";
import useApp from "../../Hooks/useApp";
import initial_user from "./initial_user";
import { useNavigate, Link } from "react-router-dom";
import backgroundImage from "../../Assets/images/bg.png";
import { storeEncryptedData } from "../../Util/store";

const LoginForm = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState(initial_user);
  const { setUser, setToken } = useApp();

  const request = useAxios();

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "members/login",
      body: {
        email: data?.email,
        password: data?.password,
      },
      show_loading: true,
      show_error: true,
    });

    if (res !== "error") {
      setUser(res.user);
      storeEncryptedData('token', res.token); 
      setToken(res.token);
      storeEncryptedData('user', res.user);
      navigate("/dashboard");
    }
  };

  const { handleValidation, validate } = useForm({
    submit: handleSubmit,
    input: input,
  });

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="w-full max-w-xl bg-neutral-white bg-opacity-80 p-12 rounded-lg shadow-lg border border-light_grey">
        <div className="flex justify-center mb-8">
          <h3 className="text-4xl font-bold text-primary">Welcome Back!</h3>
        </div>
        <form className="space-y-6">
          <div>
            <EmailInput
              input={input}
              setInput={setInput}
              field="email"
              validate={validate}
              className=""
            />
          </div>
          <div>
            <PasswordInput
              input={input}
              setInput={setInput}
              field="password"
              validate={validate}
              className=""
            />
          </div>
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <Checkbox input={input} setInput={setInput} field="checkbox" />
            </div>
            <Link
              className="text-primary hover:underline font-semibold"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </div>
          <Btn
            onClick={handleValidation}
            size="block"
            rounded={true}
          >
            Log In
          </Btn>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
