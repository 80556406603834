import CryptoJS from "crypto-js";

const KEY = "1a2cf75099cc9d74a542c3e8de3c90ce2f72fd79dc71c75acd22307083bb5ebb";

// Encryption function
const encryptData = (data, key) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    key
  ).toString();

  return encryptedData;
};

// Decryption function
const decryptData = (encryptedData, key) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);

  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

// Store encrypted data in localStorage

const storeEncryptedData = (keyName, data) => {
  const encryptionKey = KEY;

  const encryptedData = encryptData(data, encryptionKey);

  localStorage.setItem("fleet_management " + keyName, encryptedData);
};

// Retrieve and decrypt data from localStorage

const getDecryptedData = (keyName) => {
  const encryptionKey = KEY;

  const encryptedData = localStorage.getItem("fleet_management " + keyName);

  if (encryptedData) {
    const decryptedData = decryptData(encryptedData, encryptionKey);

    return decryptedData;
  }
  return null;
};

const clearEncryptedData = (keyName) => {
  if (keyName === "*") localStorage.clear();
  else localStorage.removeItem("fleet_management " + keyName);
};

export { storeEncryptedData, getDecryptedData, clearEncryptedData };
