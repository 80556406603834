import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import Notifications from "./Notification";
import { IoChevronDown } from "react-icons/io5";
import useApp from "../../Hooks/useApp";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../BreadCrumb";
import { HiOutlineLogout } from "react-icons/hi";
import { FaHistory } from "react-icons/fa";
import { clearEncryptedData } from "../../Util/store";
import Settings from "./Settings";
import MenuBtn from "./MenuBtn";

function Topbar() {
  const [openChev, setOpenChev] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setSideBarOpen, sideBarOpen, device, user } = useApp();

  const toggleChev = () => {
    setOpenChev(!openChev);
  };

  useEffect(() => {
    if (device === "sm") {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [device]);

  const handleLogout = () => {
    navigate("/");
    clearEncryptedData("*");
  };

  return (
    <div className="w-full bg-white py-4 px-4 flex flex-wrap justify-between items-center shadow-md">
      {/* Left Section: Menu and Greeting/Breadcrumb */}
      <div className="flex items-center gap-4 flex-1">
        <MenuBtn />
        {location.pathname === "/dashboard" ? (
          user ? (
            <p className="text-2xl md:text-3xl font-semibold text-gray-500">
              Hello, {user.name}!
            </p>
          ) : (
            <p className="text-2xl md:text-3xl font-semibold text-gray-500">
              Welcome 👋
            </p>
          )
        ) : location.pathname.includes("account-management") ||
          location.pathname.includes("exam-management") ? null : (
          <Breadcrumb />
        )}
      </div>

      {/* Right Section: Notifications, Avatar, and Settings */}
      <div className="flex flex-wrap gap-4 items-center justify-end">
        <hr className="hidden max-md:block w-full mt-4 border-t" />

        <div className="flex gap-6 items-center">
          <Settings />
          <Notifications />
          <div className="relative">
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={toggleChev}
            >
              <Avatar />
              <IoChevronDown
                className={`text-2xl text-dark_blue transform ${
                  openChev ? "rotate-180" : "rotate-0"
                } transition duration-200`}
              />
            </div>

            {/* Dropdown Menu */}
            {openChev && (
              <div className="absolute bg-white w-40 border rounded-lg shadow-lg p-3 right-0 top-12 z-50">
                <div
                  className="flex items-center gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-lg"
                  onClick={handleLogout}
                >
                  <HiOutlineLogout className="text-lg text-gray-600" />
                  <p className="text-sm text-gray-700">Log Out</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
