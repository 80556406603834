// Table head Text

function THT(props) {
  const { txt = "Head" } = props;
  return (
    <th className="uppercase text-md px-4 font-semibold py-2 text-primary">
      {txt}
    </th>
  );
}

export default THT;
