import React, { useEffect, useState } from "react";
import { TextInput, SelectInput } from "../../Components/Inputs";
import init_member from "../init_member";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";

const Add = () => {
  const [input, setInput] = useState(init_member);

  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);

  const request = useAxios();

  useEffect(() => {
    getRoles();
  }, []);

  async function getRoles() {
    let res = await request({
      method: "get",
      url: "roles/all",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setRoles(res?.docs);
    }
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "members/add",
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/members");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Member</h3>
      <div className="mt-4">
        <TextInput
          input={input}
          setInput={setInput}
          field="name"
          validate={validate}
        />
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="email"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="phone"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <SelectInput
            input={input}
            setInput={setInput}
            field="role_id"
            validate={validate}
          >
            <option value="" disabled>
              Select Role
            </option>
            {roles.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
          </SelectInput>
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/members")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
