const init_dispatch = {
  vehicle_id: {
    value: "",
    label: "Vehicle",
    placeholder: "Search vehicle by Plate No.",
  },
  route_id: {
    value: "",
    label: "Route",
    placeholder: "Search Route",
  },
  departure_point: {
    value: "",
    label: "Departure Point",
    placeholder: "Enter Departure Point",
  },
  destination: {
    value: "",
    label: "Destination",
    placeholder: "Enter Destination",
  },
  departure_date: {
    value: "",
    label: "Departure Date",
    placeholder: "Pick Departure Date",
  },
  expected_arrival_date: {
    value: "",
    label: "Expected Arrival Date",
    placeholder: "Pick Expected Arrival Date",
  },
  departure_time: {
    value: "",
    label: "Departure Time",
    placeholder: "Pick Departure Time",
  },
  arrival_time: {
    value: "",
    label: "Arrival Time",
    placeholder: "Pick Arrival Time",
  },
  scheduled_by: {
    value: "",
    label: "Scheduled By",
    placeholder: "Enter Name of Person Scheduling",
  },
  trip_status_id: {
    value: "",
    label: "Status",
    placeholder: "Select Status",
  },
};

export default init_dispatch;
