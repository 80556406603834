const init_expense = {
  name: {
    value: "",
    label: "Expense Name",
    placeholder: "Enter Expense Name",
  },
  amount: {
    value: "",
    label: "Amount",
    placeholder: "Enter Amount",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Enter Description",
  },
};

export default init_expense;
