import React, { useState } from "react";
import { TextInput, TextArea } from "../../Components/Inputs";
import SearchableSelect from "../../Components/Inputs/SearchableSelect";
import init_inventory from "../init_inventory";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useForm from "../../Components/hooks/useForm";
import useAxios from "../../../../Hooks/useAxios";

const Add = () => {
  const [input, setInput] = useState(init_inventory);
  const navigate = useNavigate();
  const request = useAxios();
  const [warehouses, setWarehouses] = useState({});
  // const [members, setMembers] = useState({})

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "inventory/add",
      body: data,
    });

    if (res === "error") return;
    navigate("/dashboard/inventory");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Inventory Item</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="item_name"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="quantity"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="total_cost"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="cost_per_item"
            validate={validate}
          />
        </div>
        <div className="flex gap-5 items-center max-md:flex-col">
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="warehouse_id"
            validate={validate}
            url="warehouses/search"
            setOptions={setWarehouses}
          >
            {warehouses?.docs?.map((warehouse, i) => (
              <option value={warehouse?.id} key={i} label={warehouse.name}>
                {warehouse?.name}
              </option>
            ))}
          </SearchableSelect>

          {/* <SearchableSelect
            input={input}
            setInput={setInput}
            field="created_by"
            validate={validate}
            url="members/search"
            setOptions={setMembers}
          >
            {members?.docs?.map((member, i) => (
              <option value={member?.id} key={i} label={member.name}>
                {member?.name}
              </option>
            ))}
          </SearchableSelect> */}
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/inventory")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
