import React from "react";
import VehicleReports from '../../../../Features/Tables/Reports/VehicleReports'

const All = () => {
  return <div>
    <VehicleReports />
  </div>;
};

export default All;
