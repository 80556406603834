import Aside from "./Aside";
import MainSection from "./MainSection";
import Topbar from "../Topbar";
import useApp from "../../Hooks/useApp";

function Layout(props) {
  const { SideBar, Section, Nav = null } = props;
  const { sideBarOpen } = useApp();

  return (
    <div className="w-screen h-screen flex flex-row">
      <Aside>
        <SideBar />
      </Aside>
      <MainSection>
        <Topbar />
        <div className="w-full">
          <Section />
        </div>
      </MainSection>
    </div>
  );
}

export default Layout;
