import { TR, TDT, TA, TD } from "../../components";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import useApp from "../../../../Hooks/useApp";
import { hasOtherPermission } from "../../../../Util/Permissions";

function TableRow(props) {
  const { doc } = props;
  const { user, setShowNotAuthorized } = useApp();
  const navigate = useNavigate();
  const { setShowDeleteModal, setSystemId, systemId } =
    useContext(TableContext);

  function showDelete() {
    setSystemId(doc?.id);
    setShowDeleteModal(true);
  }

  function handleAuth() {
    setShowNotAuthorized(true);
  }

  return (
    <TR>
      <TDT name="Registration No" txt={doc?.plate_no || "--"} />
      <TDT name="Model" txt={doc?.make || "--"} />
      <TDT name="VIN" txt={doc?.vin || "--"} />
      <TDT name="Fuel Capacity" txt={doc?.fuel_capacity || "--"} />
      {/* <TDT name="Assigned Driver" txt={doc?.driver || "--"} /> */}
      <TDT name="Revenue" txt={doc?.revenue || "--"} />
      <TDT name="Mileage" txt={doc?.mileage || "--"} />
      <TDT name="Fleet Number" txt={doc?.fleet_no || "--"} />
      <TDT name="Trailor Number" txt={doc?.trailer_no || "--"} />
      <TDT
        name="ACTION"
        txt={
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`view/${doc?.id}`)}
              >
                <FaEye className="text-blue-500 text-2xl text-center" />
              </p>
            </div>
            <div className="cursor-pointer">
              <p
                className="text-center flex gap-2"
                onClick={() => navigate(`edit/${doc?.id}`)}
              >
                <FiEdit2 className="text-2xl text-center" />
              </p>
            </div>
            <div onClick={showDelete} className="cursor-pointer">
              <p className="text-center flex gap-2">
                <RiDeleteBin5Line className="text-red text-2xl text-center cursor-pointer" />
              </p>
            </div>
          </div>
        }
      />
    </TR>
  );
}

export default TableRow;
