/* eslint-disable react/prop-types */
import {
  btnClass,
  btnSizes,
  btnPadding,
  btnRounded,
} from "../../Util/config/Btn";

const Btn = ({
  btn = "primary",
  outline = false,
  className = "",
  custom = false,
  rounded = false,
  size = "auto",
  disabled = false,
  onClick = () => {},
  padding = "lg",
  children,
}) => {
  function clickBtn() {
    if (!disabled) {
      onClick();
    }
  }

  const styles = custom
    ? `${className}`
    : `${btnPadding[padding] ? btnPadding[padding] : btnPadding["lg"]}
    ${btnClass[btn] ? btnClass[btn][outline] : btnClass["primary"][outline]} 
    ${btnRounded[rounded] ? btnRounded[rounded] : btnRounded["md"]} 
    ${className} `;

  return (
    <div
      onClick={clickBtn}
      className={`
        ${
          disabled
            ? "cursor-not-allowed bg-gray-300 opacity-50"
            : "cursor-pointer hover:shadow active:shadow-none active:bg-opacity-70 duration-100"
        }
        ${btnSizes[size] ? btnSizes[size] : btnSizes["auto"]}
        ${styles}
        ${className} `}
    >
      {children}
    </div>
  );
};

export default Btn;
