import React from "react";
import EditWarehouse from "../../../Features/Forms/Warehouses/Edit";

const Edit = () => {
  return (
    <div>
      <EditWarehouse />
    </div>
  );
};

export default Edit;
