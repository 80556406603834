import React from "react";
import NavBtn from "../NavBtn";
import { LiaWarehouseSolid } from "react-icons/lia";

const Warehouses = (props) => {
  const {
    exactPath = "/dashboard/warehouses",
    to = "/dashboard/warehouses",
    partialPath = "/warehouses",
    label = "Warehouses",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={LiaWarehouseSolid}
    />
  );
};

export default Warehouses;