const init_routes = {
  name: {
    value: "",
    label: "Route Name",
    placeholder: "Enter Name",
  },
  no_of_stops: {
    value: "",
    label: "Number Of Stops",
    placeholder: "Enter Number Of Stops",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Enter Description",
  },
};

export default init_routes;
