import React, { useState, useEffect } from "react";
import { TextInput, TextArea } from "../../Components/Inputs";
import SearchableSelect from "../../Components/Inputs/SearchableSelect";

import init_inventory from "../init_inventory";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../Components/hooks/useForm";
import useAxios from "../../../../Hooks/useAxios";

const Edit = () => {
  const { id } = useParams();
  const [input, setInput] = useState(init_inventory);
  const navigate = useNavigate();
  const request = useAxios();

  const [warehouses, setWarehouses] = useState({})
  const [members, setMembers] = useState({})


  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInventory();
  }, []);

  async function getInventory() {
    let res = await request({
      method: "get",
      url: "inventory/single-inventory",
      params: {
        id: parseInt(id),
      },
    });
    if (res === "error") return;
    const inputCpy = { ...init_inventory };
    inputCpy.item_name.value = res?.item_name;
    inputCpy.quantity.value = res?.quantity;
    inputCpy.total_cost.value = res?.total_cost;
    inputCpy.cost_per_item.value = res?.cost_per_item;

    setInput(inputCpy);
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "PUT",
      url: "inventory/update",
      body: { ...data, id: parseInt(id) },
      show_loading: true,
      loadingFunc: setIsLoading,
    });

    navigate("/dashboard/inventory");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Inventory Item</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput input={input} setInput={setInput} field="item_name" />
          <TextInput input={input} setInput={setInput} field="quantity" />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput input={input} setInput={setInput} field="total_cost" />
          <TextInput input={input} setInput={setInput} field="cost_per_item" />
        </div>
        <div className="flex gap-5 items-center max-md:flex-col">
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="warehouse_id"
            validate={validate}
            url="warehouses/search"
            setOptions={setWarehouses}
          >
            {warehouses?.docs?.map((warehouse, i) => (
              <option value={warehouse?.id} key={i} label={warehouse.name}>
                {warehouse?.name}
              </option>
            ))}
          </SearchableSelect>

          <SearchableSelect
            input={input}
            setInput={setInput}
            field="created_by"
            validate={validate}
            url="members/search"
            setOptions={setMembers}
          >
            {members?.docs?.map((member, i) => (
              <option value={member?.id} key={i} label={member.name}>
                {member?.name}
              </option>
            ))}
          </SearchableSelect>
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/inventory")}>Back</Btn>
        <Btn onClick={handleValidation}>Update</Btn>
      </div>
    </div>
  );
};

export default Edit;
